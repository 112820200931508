import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CustomerService } from 'src/app/services/customer.service';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss', './customer-detail.responsive.scss']
})
export class CustomerDetailComponent implements OnInit {

  public loading: boolean = false

  public customer: any = { summary: {} }

  constructor(
    public app: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private toast: TgToast,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.getCustomer(params['_id'])
    })
  }

  getCustomer(_id) {
    this.loading = true

    this.customerService.findById(_id, this.app.currentVenue._id).subscribe(
      data => this.setCustomer(data),
      error => this.error(error)
    )
  }

  setCustomer(customer) {
    this.customer = customer
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
