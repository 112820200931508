import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tg-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss']
})

export class CardComponent implements OnInit {

  //@Input() background: string = 'white'
  @Input() padding: string = '8px'
  @Input() radius: string = '4px'
  @Input() elevate: number = 4
  @Input() background: string

  boxShadow: string = `0 ${this.elevate}px ${this.elevate * 2}px 0 rgba(0,0,0,0.2);`

  constructor() { }

  ngOnInit(): void {
  }

}
