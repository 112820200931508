<div class="wrapper" fxLayout="column" fxLayoutGap="8px">

	<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
		<tg-button (click)="cancel()">
			<div fxLayout="row" fxLayoutAlign="start center">
				<tg-icon src="../../../assets/icons/back.svg" [color]="color?.PRIMARY"></tg-icon>
				<span>Voltar</span>
			</div>
		</tg-button>

		<span class="margin-16"> <span class="color-primary">{{ count.email }}</span> {{ count.email && count.email === 1 ? 'cliente' : 'clientes' }} com email cadastrado, serão impactados. </span>
	</div>

	<div fxLayout="column" fxLayoutGap="16px" class="padding-8 scroll-y">

		<div fxLayout="row" fxLayoutAlign="center center">
			<tg-email-preview
				[headerBackground]="email?.details?.headerBackground"
				[bodyBackground]="email?.details?.bodyBackground"
				[bodyText]="email?.details?.bodyText"
				[buttonText]="email?.details?.buttonText"
				[buttonLink]="email?.details?.buttonLink"
				[buttonColor]="email?.details?.buttonColor"
				[buttonBackground]="email?.details?.buttonBackground"
				[buttonBorder]="email?.details?.buttonBorder"
				[loadHeaderImage]="headerImage"
				[loadBodyImage]="bodyImage"
				[canUpdateImages]="false"></tg-email-preview>
		</div>

		<form [formGroup]="testForm" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="8px" class="padding-8" >
			<tg-textfield formControlName="destination" fxFlex type="email" label="Email de teste" placeholder="email@teste.com.br"></tg-textfield>
			<tg-button type="raised" (click)="sendTest()" [disabled]="loading || testForm.invalid">Testar</tg-button>
		</form>

		<div fxLayout="row">
			<tg-button width="100%" type="raised" (click)="send()" [disabled]="loading || !count">Enviar</tg-button>
		</div>

	</div>