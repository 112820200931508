<div class="wrapper" fxLayout="column">

  <div class="scroll-y" fxHide.xs>
    <tg-table
      [showPlaceholder]="!emails.length"
      placeholder="Você ainda não criou um template de email pelo Tagme CRM."
      [loading]="loading"
      animationSrc="../../../assets/animations/food.json"
      animationSpeed="4">

      <thead>
        <tr>
          <th>Título</th>
          <th>Enviados</th>
          <th>Abertos</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let row of emails" (click)="select(row)">
          <td>{{ row.title }}</td>
          <td end>{{ row.sent }}</td>
          <td end>{{ row.open }}</td>
          
          <td fxLayout="row" fxLayoutAlign="center center">
            <input type="checkbox" [(ngModel)]="row.selected" />
          </td>
        </tr>
      </tbody>
    </tg-table>

    <div fxHide.gt-xs fxShow.xs fxFlex fxLayout="column" fxLayoutGap="16px" class="scroll-y padding-8">
      <tg-card *ngFor="let row of emails" width="100%" padding="16px" (click)="select(row)">
        <div fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <input type="checkbox" [(ngModel)]="row.selected" />
            <span class="name">{{row.title}}</span>
          </div>

          <div fxLayout="row">
            <span class="label" fxFlex>Enviados:</span>
            <span class="sent">{{ row.sent }}</span>
          </div>

          <div fxLayout="row">
            <span class="label" fxFlex>Abertos:</span>
            <span class="sent">{{ row.open }}</span>
          </div>
        </div>
      </tg-card>
    </div>

  </div>

  <div class="margin-16" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <tg-button (click)="cancel()">Cancelar</tg-button>
    <tg-button type="raised" (click)="ok()" [disabled]="!currentEmail">Confirmar Seleção</tg-button>
  </div>

</div>
