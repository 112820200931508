import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from './token.service'
import { environment } from '../../environments/environment';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  auth: string

  constructor(private tokenService: TokenService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        apitoken: environment.APITOKEN
      }
    })

    this.auth = this.tokenService.getToken()

    if (this.auth) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth}`
        }
      })
    }

    return next.handle(request);

  }

}