<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="42px">

  <div fxLayout="row" fxLayout.xs="column">
    <span class="title">Você {{vouchers.length ? 'tem ' + vouchers.length : 'não tem'}} {{vouchers.length === 1 ? 'voucher' : 'vouchers'}} pelo Tagme CRM.</span>

    <div fxFlex></div>

    <tg-button fxFlex.xs type="raised" (click)="dialogCreate()">Criar voucher promocional</tg-button>
  </div>

  <tg-table
    fxHide.xs
    [showPlaceholder]="!vouchers.length"
    placeholder="Você ainda não criou um voucher pelo Tagme CRM."
    [loading]="loading"
    animationSrc="../../../assets/animations/food.json"
    animationSpeed="4">
    <thead>
      <tr>
        <th>Nome da campanha</th>
        <th>Benefício</th>
        <th>Código do voucher</th>
        <!--<th>Regras</th>-->
        <th>Gerados</th>
        <th>Utilizados</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of vouchers">
        <td>{{ row.name }}</td>
        <td>{{ row.description }}</td>
        <td>{{ row.voucher }}</td>
        <!--<td>{{ row.rules }}</td>-->
        <td end>{{ row.quantity }}</td>
        <td end>0</td>
        
        <td end>
          <tg-button type="outline" (click)="dialogUpdate(row._id)">Editar</tg-button>
        </td>
      </tr>
    </tbody>
  </tg-table>

  <div fxHide.gt-xs fxShow.xs fxFlex fxLayout="column" fxLayoutGap="16px" class="scroll-y padding-8">
    <tg-card *ngFor="let row of vouchers" width="100%" padding="16px">
      <div fxLayout="column" fxLayoutGap="16px">
        <span class="name">{{row.name}}</span>

        <div fxLayout="column" fxLayoutGap="4px">
          <span class="label">Benefício:</span>
          <span class="text">{{ row.description }}</span>
        </div>

        <div fxLayout="row">
          <span class="label" fxFlex>Código do voucher:</span>
          <span class="sent">{{ row.voucher }}</span>
        </div>

        <div fxLayout="row">
          <span class="label" fxFlex>Gerados:</span>
          <span class="sent">{{ row.quantity }}</span>
        </div>

        <div fxLayout="row">
          <span class="label" fxFlex>Utilizados:</span>
          <span class="sent">{{ 0 }}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
          <tg-button type="outline" fxFlex width="100%" (click)="dialogUpdate(row._id)">Editar</tg-button>
        </div>
      </div>
    </tg-card>
  </div>

</div>