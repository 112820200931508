import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { TokenService } from './services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public loggedUser
  public venues
  public currentVenue
  public currentUrl

  public permittedRoutes = ['/signin']

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  title = 'tagme-crm-frontend';


  public color = {
    PRIMARY: '#FF7E40',
    AFFIRMATIVE: '#388E3C'
  }

  public navigation = [
    {
      urls: ['/dashboard'],
      routerLink: '/dashboard',
      icon: '../assets/icons/explore.svg',
      text: 'Visão geral e envio',
      shortText: 'dashboard',
      selected: false,
      enabled: true
    },

    /*
    {
      urls: ['/voucher'],
      routerLink: '/voucher',
      icon: '../assets/icons/confirmation.svg',
      text: 'Gestão de Vouchers',
      shortText: 'Vouchers',
      selected: false,
      enabled: false
    },
    */

    {
      urls: ['/sms', '/sms/create', '/sms/send'],
      routerLink: '/sms',
      icon: '../assets/icons/chat.svg',
      text: 'Gestão e envio de SMS',
      shortText: 'SMS',
      selected: false,
      enabled: true
    },

    {
      urls: ['/email', '/email/create'],
      routerLink: '/email',
      icon: '../assets/icons/email.svg',
      text: 'Gestão e envio de email',
      shortText: 'Email',
      selected: false,
      enabled: true
    },

    {
      urls: ['/communication-report'],
      routerLink: '/communication-report',
      icon: '../assets/icons/send.svg',
      text: 'Envios',
      shortText: 'Envios',
      selected: false,
      enabled: true
    },

    /*
    {
      urls: ['/customer'],
      routerLink: '/customer',
      icon: '../assets/icons/group.svg',
      text: 'Meus clientes',
      shortText: 'Clientes',
      selected: false,
      enabled: true
    }
    */
  ]

  gotoSignin() {
    this.router.navigateByUrl('/signin')
  }

  public setNavigation(url) {
    const token = this.tokenService.getToken()
    const permitted = this.permittedRoutes.includes(url)

    if (!token && !permitted) {
      this.gotoSignin()
      return false
    }

    this.currentUrl = url

    const item = this.navigation.find(d => d.urls.includes(url))
    this.navigation.map(d => d.selected = false)

    if (item) item.selected = true
  }

  public reload() {
    const url = this.currentUrl

    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate([url])
      })
  }

  public logout() {
    this.tokenService.destroyUser()
    this.tokenService.destroyToken()
    this.tokenService.destroyVenues()
    this.tokenService.destroyCurrentVenue()
    this.gotoSignin()
  }

}


/*
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

}
*/