<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="24px">

  <div fxLayout="row">
    <tg-button routerLink="/sms">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <tg-icon src="../../../assets/icons/back.svg" [color]="app.color.PRIMARY"></tg-icon>
        <span>voltar</span>
      </div>
    </tg-button>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="24px">

    <div fxFlex fxLayout="colum" fxLayoutAlign.lt-md="center center">

      <tg-card class="form" padding="16px">
        <div fxLayout="column" fxLayoutGap="24px">

          <form [formGroup]="smsForm" fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="column" fxLayoutGap="8px">
              <span class="label">Título</span>
              <span class="hint">O texto do título não será enviado para o cliente. Crie um título para que você possa
                organizar seus SMS's.</span>

              <tg-textfield formControlName="title" placeholder="Dia das Mães"></tg-textfield>
            </div>

            <div fxLayout="column" fxLayoutGap="8px">
              <span class="label">Mensagem padrão</span>

              <textarea formControlName="text" rows="7" maxlength="124"></textarea>

              <span class="hint">{{ smsForm.get('text').value.length }} de 124 caracteres.</span>
            </div>
          </form>

          <tg-button width="100%" type="raised" [disabled]="smsForm.invalid || loading" (click)="save()">Salvar</tg-button>
        </div>
      </tg-card>

    </div>

    <div fxFlex fxLayout="colum">
      <div class="smartphone" fxLayout="column">
        <div fxLyout="row" class="padding-8">
          <span>{{venueName}}</span>
        </div>

        <div fxFlex fxLayoutAlign="center center" class="container">
          <textarea _ngcontent-syw-c8="" disabled="disabled" id="exampleFormControlInput1"
            placeholder="Previsualização" rows="5" type="text" [value]="this.smsForm.get('text').value"></textarea>
        </div>
      </div>
    </div>

  </div>

</div>