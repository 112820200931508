<div class="wrapper" fxLayout="column" fxLayoutGap="16px">

  <image-cropper
    [imageChangedEvent]="event"
    [imageFile]="file"
    [maintainAspectRatio]="true"
    [aspectRatio]="ratio"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"></image-cropper>

    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px">
      <tg-button type="text" (click)="cancel()">Cancelar</tg-button>
      <tg-button type="raised" (click)="ok()" [disabled]="!croppedImage">OK</tg-button>
    </div>

</div>