import { Component, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-sms-send-template',
  templateUrl: './sms-send-template.component.html',
  styleUrls: ['./sms-send-template.component.scss', './sms-send-template.responsive.scss']
})
export class SmsSendTemplateComponent implements OnInit {

  loading: boolean = false
  public smsList: Array<any> = []
  public currentSms: any

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    private communicationTemplateService: CommunicationTemplateService,
    private toast: TgToast
  ) { }

  ngOnInit(): void {
    this.getSmsList()
  }

  getSmsList() {
    this.loading = true

    this.communicationTemplateService.find(
      { type: 'sms', deleted: false },
      { title: 1, text: 1, sentAmount: 1 },
      { sort: '-created_at' }
    ).subscribe(
      data => this.setSmsList(data),
      error => this.error(error)
    )
  }

  setSmsList(smsList) {
    this.smsList = smsList
    this.loading = false
  }

  select(sms) {
    this.smsList.forEach(d => d.selected = false)
    sms.selected = true

    this.currentSms = sms
  }

  cancel(): void {
    this.dialog.close({action: 'CANCEL'})
  }

  ok(): void {
    this.dialog.close({ action: 'OK', data: { sms: this.currentSms } })
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
