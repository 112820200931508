import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as qs from 'qs';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class CrmCategoryService extends TokenService {

  url: string

  constructor(
    public http: HttpClient,
  ) {
    super()
    
    this.url = `${environment.API_HOST}/crmcategory`;
  }

  find(params = {}, fields = {}, options = {}) {
    const querystring = qs.stringify({ params, fields, options }, { encode: false })
    return this.http.get(`${this.url}?${querystring}`)
  }

  kpis(match = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next([]))

    const querystring = qs.stringify({ match }, { encode: false })
    return this.http.get(`${this.url}/${venue}/kpis?${querystring}`)
  }

  customersCount(match = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next(0))

    const querystring = qs.stringify({ match }, { encode: false })
    return this.http.get(`${this.url}/${venue}/customers/count?${querystring}`)
  }

}