import { Component, OnInit } from '@angular/core';
import { TgDialog } from '../../../components/dialog/dialog'
import { AppComponent } from 'src/app/app.component';
import { VoucherService } from 'src/app/services/voucher.service';
import { VoucherCreateComponent } from '../../pages/voucher-create/voucher-create.component'
import { VoucherUpdateComponent } from '../../pages/voucher-update/voucher-update.component'
import { Router } from '@angular/router';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss', './voucher.responsive.scss']
})
export class VoucherComponent implements OnInit {

  public loading: boolean = false
  public vouchers: Array<any> = []

  constructor(
    private router: Router,
    public app: AppComponent,
    private dialog: TgDialog,
    private voucherService: VoucherService,
    private toast: TgToast,
  ) { }

  ngOnInit(): void {
    this.getVouchers()
  }

  getVouchers() {
    this.loading = true

    this.voucherService.find({}, {}, { sort: '-created_at' }).subscribe(
      data => this.setVouchers(data),
      error => this.error(error)
    )
  }

  setVouchers(vouchers) {
    this.vouchers = vouchers
    this.loading = false
  }

  dialogCreate() {
    const dialogRef = this.dialog.open(VoucherCreateComponent, {
      width: '250px',
      height: 'auto',
      data: {},
    });

    dialogRef.afterClosed.subscribe(result => {
      if (result && result.action === 'SAVE') this.save(result.data)
    });
  }

  save(voucher) {
    const data = {
      ...voucher,
      origin: 'crm',
      venue: this.app.currentVenue._id
    }

    this.voucherService.insert(data).subscribe(
      data => this.saveSuccess(),
      error => this.error(error)
    )
  }

  dialogUpdate(_id) {
    const dialogRef = this.dialog.open(VoucherUpdateComponent, {
      width: '250px',
      height: 'auto',
      data: { _id },
    });

    dialogRef.afterClosed.subscribe(result => {
      if (result && result.action === 'UPDATE') this.update(result._id, result.data)
    });
  }

  update(_id, voucher) {
    this.loading = true

    this.voucherService.updateById(_id, voucher).subscribe(
      data => this.updateSuccess(),
      error => this.error(error)
    )
  }

  saveSuccess() {
    this.loading = false
    this.toast.show({ text: message.VOUCHER_CREATE_SUCCESS, type: 'success' })
    this.getVouchers()
  }

  updateSuccess() {
    this.loading = false
    this.toast.show({ text: message.VOUCHER_UPDATE_SUCCESS, type: 'success' })
    this.getVouchers()
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
