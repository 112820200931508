<div class="smartphone" fxLayout="column" fxLayoutGap="8px">

	<div fxLayout="row">
		<tg-button (click)="cancel()">
			<div fxLayout="row" fxLayoutAlign="start center">
				<tg-icon src="../../../assets/icons/back.svg" [color]="color?.PRIMARY"></tg-icon>
				<span>Voltar</span>
			</div>
		</tg-button>
	</div>

	<div fxLayout="row" fxLayoutAlign="end">
		<span class="margin-16"> <span class="color-primary">{{ count.phone }}</span> {{ count.phone && count.phone === 1 ? 'cliente' : 'clientes' }} com telefone cadastrado, serão impactados. </span>
	</div>
	

	<div fxFlex fxLayoutAlign="center center">
		<div class="container">
			<textarea _ngcontent-syw-c8="" disabled="disabled" id="exampleFormControlInput1" placeholder="Previsualização"
				rows="5" type="text" [value]="sms.text"></textarea>
		</div>
	</div>

	<form [formGroup]="testForm" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="8px" class="padding-8" >
		<tg-textfield formControlName="destination" fxFlex type="text" label="Número de teste" placeholder="(99) 99999-9999" mask="(00) 00000-0000"></tg-textfield>
		<tg-button type="raised" (click)="sendTest()" [disabled]="loading || testForm.invalid">Testar</tg-button>
	</form>

	<div fxLayout="row" class="padding-8">
		<tg-button width="100%" type="raised" (click)="send()" [disabled]="loading || !count">Enviar para todos</tg-button>
	</div>

</div>