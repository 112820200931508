import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../services/app.service'
import { environment } from '../../environments/environment';
import * as qs from 'qs';

@Injectable()
export class CustomerService extends AppService {

  url: string

  constructor(
    public http: HttpClient,
  ) {
    super('customer', http);
    this.url = `${environment.API_HOST}/customer`;
  }

  findById(id = '', venue = '', fields = {}) {
    const querystring = qs.stringify({ venue, fields }, { encode: false })
    return this.http.get(`${this.url}/${id}?${querystring}`)
  }

}