import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import * as ClassicEditor from '../../../components/@ckeditor/ckeditor';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-sms-create',
  templateUrl: './sms-create.component.html',
  styleUrls: ['./sms-create.component.scss', './sms-create.responsive.scss']
})
export class SmsCreateComponent implements OnInit {

  public Editor = ClassicEditor;
  public EditorConfig = { toolbar: [ 'bold', 'italic' ] }
  public venueName = ''
  public message = ''

  loading: boolean = false
  smsForm: FormGroup;

  constructor(
    public app: AppComponent,
    private router: Router,
    private formBuilder: FormBuilder,
    private communicationTemplateService: CommunicationTemplateService,
    private toast: TgToast,
    private tokenService: TokenService
  ) {
    this.smsForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      text: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
    const currentVenue = <any>this.tokenService.getCurrentVenue()
    this.venueName = currentVenue ? currentVenue.name.pt : ''
  }

  save() {
    if (this.smsForm.invalid) return false

    this.loading = true

    let data = { ...this.smsForm.value, type: 'sms' }

    this.communicationTemplateService.insert(data).subscribe(
      data => this.saveSuccess(),
      error => this.error(error)
    )
  }

  saveSuccess() {
    this.loading = false
    this.toast.show({ text: message.SMS_CREATE_SUCCESS, type: 'success' })
    this.router.navigate(['/sms'])
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
