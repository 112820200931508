<div class="wrapper" fxLayout="column">

  <div class="scroll-y" fxHide.xs>
    <tg-table
      [showPlaceholder]="!smsList.length"
      placeholder="Você ainda não criou um template de SMS pelo Tagme CRM."
      [loading]="loading"
      animationSrc="../../../assets/animations/food.json"
      animationSpeed="4">

      <thead>
        <tr>
          <th>Nome do SMS</th>
          <th>Texto</th>
          <!--<th>Enviados</th>-->
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let row of smsList" (click)="select(row)">
          <td>{{ row.title }}</td>
          <td>{{ row.text }}</td>
          <!--<td end>{{ row.sentAmount }}</td>-->
          
          <td fxLayout="row" fxLayoutAlign="center center">
            <input type="checkbox" [(ngModel)]="row.selected" />
          </td>
        </tr>
      </tbody>

    </tg-table>
  </div>

  <div fxHide.gt-xs fxLayout="column" fxLayoutGap="16px" class="scroll-y padding-8">
    <tg-card *ngFor="let row of smsList" width="100%" padding="16px" (click)="select(row)">
      <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <input type="checkbox" [(ngModel)]="row.selected" />
          <span class="name">{{row.title}}</span>
        </div>

        <div fxLayout="column" fxLayoutGap="4px">
          <span class="label">Texto:</span>
          <span class="text">{{ row.text }}</span>
        </div>

        <!--
        <div fxLayout="row">
          <span class="label" fxFlex>Enviados:</span>
          <span class="sent">{{ row.sentAmount }}</span>
        </div>
        -->
      </div>
    </tg-card>
  </div>

  <div class="margin-16" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <tg-button (click)="cancel()">Cancelar</tg-button>
    <tg-button type="raised" (click)="ok()" [disabled]="!currentSms">Confirmar Seleção</tg-button>
  </div>

</div>
