import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tg-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input() src: string = ''
  @Input() size: string = '24px'
  @Input() color: string = '#666666'

  constructor() { }

  ngOnInit(): void {
  }

}
