<table class="header">
  <tbody>
    <tr>
      <td [ngStyle]="{'background': headerBackground}"></td>

      <td
        class="image"
        tg-draganddrop
        (fileDragover)="onHeaderImageDragover($event)"
        (fileDragleave)="onHeaderImageDragleave($event)"
        (fileDropped)="onHeaderImageDrop($event)"
        [ngStyle]="{'background': headerBackground}" >

        <input #headerImageInput class="hidden" type="file" (change)="openCropperDialog('headerImage', $event, null, headerImageRatio)" />

        <div [ngClass]="{'hidden': headerImage}" class="placeholder" fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" fxLayoutAlign="center center" style="width: 140px;">
            <span class="hint">Logotipo (815px - 586px)</span>
            <tg-button (click)="headerImageInputTrigger()">selecione</tg-button>
          </div>
        </div>

        <div [ngClass]="{'hidden': !headerImage}"  #headerImageElement class="image"></div>
      </td>

      <td [ngStyle]="{'background': headerBackground}">
        <div *ngIf="headerImage" fxLayout="column" fxLayoutAlign="center end">
          <tg-button *ngIf="canUpdateImages" (click)="headerImageInputTrigger()">
            <tg-icon src="../../../assets/icons/edit.svg" [color]="colorPrimary" size="16px"></tg-icon>
          </tg-button>

          <div fxFlex></div>

          <tg-button *ngIf="canUpdateImages" (click)="headerImageClear()">
            <tg-icon src="../../../assets/icons/delete.svg" [color]="colorPrimary" size="16px"></tg-icon>
          </tg-button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="body">
  <tbody>
    <tr>
      <td
        class="image"
        tg-draganddrop
        (fileDragover)="onBodyImageDragover($event)"
        (fileDragleave)="onBodyImageDragleave($event)"
        (fileDropped)="onBodyImageDrop($event)">

        <input #bodyImageInput class="hidden" type="file" (change)="openCropperDialog('bodyImage', $event, null, bodyImageRatio)" />

        <div [ngClass]="{'hidden': bodyImage}" class="placeholder" fxLayout="column" fxLayoutAlign="center center">
          <span>Solte aqui sua</span>
          <span>IMAGEM DESTAQUE</span>
          <span>1000px - 725px</span>
          <tg-button (click)="bodyImageInputTrigger()">ou selecione</tg-button>

          <div fxFlex></div>

          <img src="../../../../assets/images/upload.svg" width="48px" height="48px" class="margin-top-32" />
        </div>

        <div [ngClass]="{'hidden': !bodyImage}" #bodyImageElement class="image" fxLayout="column">
          <div fxFlex></div>
          <div fxLayout="row" fxLayoutAlign="end">
            <tg-button *ngIf="canUpdateImages" (click)="bodyImageInputTrigger()">
              <tg-icon src="../../../assets/icons/edit.svg" [color]="colorPrimary" size="16px"></tg-icon>
            </tg-button>

            <tg-button *ngIf="canUpdateImages"  (click)="bodyImageClear()">
              <tg-icon src="../../../assets/icons/delete.svg" [color]="colorPrimary" size="16px"></tg-icon>
            </tg-button>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td class="text" [ngStyle]="{'background': bodyBackground}">
        <div *ngIf="!bodyText" class="placeholder" fxLayout="column" fxLayoutAlign="center center">
           <span>Área para você escrever seu texto</span>
        </div>
        
        <div *ngIf="bodyText" class="text" [innerHTML]="bodyText | sanitizer"></div>
      </td>
    </tr>
    <tr *ngIf="buttonText">
      <td class="button" [ngStyle]="{'background': bodyBackground}"> <a class="button" [href]="buttonLink"
          [ngStyle]="{'color': buttonColor, 'background': buttonBackground, 'borderColor': buttonBorder}">{{buttonText}}</a>
      </td>
    </tr>
  </tbody>
</table>

<table class="footer">
  <tbody>
    <tr>
      <td class="logo">
        <img src="https://tagmepub.blob.core.windows.net/pubimg/crm/9d57d270-778b-11eb-b855-b11f13b13a10.jpg" style="width: 140px; height: 64px;" />
      </td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>