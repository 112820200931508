import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[tg-draganddrop]'
})
export class DraganddropDirective {

  private fileOver: boolean = false

  @Output() fileDragover = new EventEmitter<any>();
  @Output() fileDragleave = new EventEmitter<any>();
  @Output() fileDropped = new EventEmitter<any>();

  constructor(private el: ElementRef) {}

  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()

    if (!this.fileOver) {
      this.fileOver = true  
      this.fileDragover.emit({element: this.el.nativeElement})
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.fileOver) {
      this.fileOver = false
      this.fileDragleave.emit({element: this.el.nativeElement})
    }
  }

  @HostListener('drop', ['$event']) public ondrop(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.fileOver) {
      this.fileOver = false
      let files = event.dataTransfer.files;
      
      if (files.length > 0)
        this.fileDropped.emit({files, element: this.el.nativeElement, event})
    }
  }

}
