<div class="wrapper" fxLayout="column" fxLayoutGap="24px">

  <div class="grid-item-container">
    <div *ngFor="let category of config.data.categories" class="grid-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <input type="checkbox" [(ngModel)]="category.selected" />
      <label>{{category.name.pt}}</label>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <tg-button (click)="cancel()">Cancelar</tg-button>
    <tg-button type="raised" (click)="ok()">Confirmar Seleção</tg-button>
  </div>

</div>
