<div class="wrapper" fxLayout="column" fxLayoutGap="8px">

  <div fxLayout="row">
		<h2>{{ email.title }}</h2>
		
		<div fxFlex></div>

		<tg-button (click)="remove()">
      <tg-icon src="../../../assets/icons/delete.svg" [color]="color.PRIMARY"></tg-icon>
    </tg-button>
  </div>

	<div fxLayout="column" fxLayoutAlign="center center" class="scroll-y">

		<tg-email-preview
			[headerBackground]="email?.details?.headerBackground"
			[bodyBackground]="email?.details?.bodyBackground"
			[bodyText]="email?.details?.bodyText"
			[buttonText]="email?.details?.buttonText"
			[buttonLink]="email?.details?.buttonLink"
			[buttonColor]="email?.details?.buttonColor"
			[buttonBackground]="email?.details?.buttonBackground"
			[buttonBorder]="email?.details?.buttonBorder"
			[loadHeaderImage]="headerImage"
			[loadBodyImage]="bodyImage"
			[canUpdateImages]="false"></tg-email-preview>

	</div>

	<div fxLayout="row" class="padding-8" fxLayoutAlign="end">
		<tg-button type="raised" (click)="ok()">OK</tg-button>
	</div>

</div>