import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "../services/interceptor.service";
import { TokenService } from "../services/token.service";
import { UserService } from "../services/user.service";
import { CommunicationTemplateService } from "../services/communicationtemplate.service";
import { CommunicationInstanceService } from "../services/communicationinstance.service";
import { VoucherService } from "../services/voucher.service";
import { CrmCategoryService } from "../services/crmcategory.service";
import { CrmDashboardService } from "../services/crmdashboard.service";
import { UnsubscribeService } from "../services/unsubscribe.service";

import { CrmRoutingModule } from "./crm-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CardModule } from "../components/card/card.module";
import { TextfieldModule } from "../components/textfield/textfield.module";
import { ButtonModule } from "../components/button/button.module";
import { DividerModule } from "../components/divider/divider.module";
import { IconModule } from "../components/icon/icon.module";
import { TableModule } from "../components/table/table.module";
import { CheckboxModule } from "../components/checkbox/checkbox.module";
import { DraganddropModule } from "../components/draganddrop/draganddrop.module";
import { StopPropagationModule } from "../components/stop-propagation/stop-propagation.module";
import { TreeModule } from "../components/tree/tree.module";
import { DialogModule } from "../components/dialog/dialog.module";
import { ToastModule } from "../components/toast/toast.module";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { TagInputModule } from "ngx-chips";
import { ImageCropperModule } from "ngx-image-cropper";
import { TooltipModule } from "ng2-tooltip-directive";
import { NgxChartsModule } from "@swimlane/ngx-charts";

import { PipesModule } from "./pipes/pipes.module";

import { HeaderComponent } from "./components/header/header.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { SmsComponent } from "./pages/sms/sms.component";
import { EmailComponent } from "./pages/email/email.component";
import { CrmComponent } from "./crm.component";
import { SmsCreateComponent } from "./pages/sms-create/sms-create.component";
import { EmailCreateComponent } from "./pages/email-create/email-create.component";
import { EmailUpdateComponent } from "./pages/email-update/email-update.component";
import { CustomerComponent } from "./pages/customer/customer.component";
import { DialogDashboardGroupsComponent } from "./components/dialog-dashboard-groups/dialog-dashboard-groups.component";
import { VoucherComponent } from "./pages/voucher/voucher.component";
import { CustomerDetailComponent } from "./pages/customer-detail/customer-detail.component";
import { DialogConfirmationComponent } from "./components/dialog-confirmation/dialog-confirmation.component";
import { DialogEmailViewComponent } from "./components/dialog-email-view/dialog-email-view.component";
import { SmsUpdateComponent } from "./pages/sms-update/sms-update.component";
import { CustomerService } from "../services/customer.service";
import { VoucherCreateComponent } from "./pages/voucher-create/voucher-create.component";
import { VoucherUpdateComponent } from "./pages/voucher-update/voucher-update.component";
import { BottomNavigationComponent } from "./components/bottom-navigation/bottom-navigation.component";
import { DialogSmsViewComponent } from "./components/dialog-sms-view/dialog-sms-view.component";
import { SmsSendComponent } from "./pages/sms-send/sms-send.component";
import { SmsSendTemplateComponent } from "./pages/sms-send-template/sms-send-template.component";
import { SmsSendReviewComponent } from "./pages/sms-send-review/sms-send-review.component";
import { EmailSendComponent } from "./pages/email-send/email-send.component";
import { EmailSendReviewComponent } from "./pages/email-send-review/email-send-review.component";
import { EmailSendTemplateComponent } from "./pages/email-send-template/email-send-template.component";
import { EmailPreviewComponent } from "./components/email-preview/email-preview.component";
import { DialogEmailCropperComponent } from "./components/dialog-email-cropper/dialog-email-cropper.component";
import { CommunicationReportComponent } from "./pages/communication-report/communication-report.component";
import { UnsubscribeComponent } from "./pages/unsubscribe/unsubscribe.component";
import { UnsubscribeConfirmationComponent } from "./pages/unsubscribe-confirmation/unsubscribe-confirmation.component";

@NgModule({
  declarations: [
    CrmComponent,
    DashboardComponent,
    SmsComponent,
    EmailComponent,
    HeaderComponent,
    SmsCreateComponent,
    EmailCreateComponent,
    EmailUpdateComponent,
    CustomerComponent,
    DialogDashboardGroupsComponent,
    VoucherComponent,
    CustomerDetailComponent,
    DialogConfirmationComponent,
    DialogEmailViewComponent,
    SmsUpdateComponent,
    VoucherCreateComponent,
    VoucherUpdateComponent,
    BottomNavigationComponent,
    DialogSmsViewComponent,
    SmsSendComponent,
    SmsSendTemplateComponent,
    SmsSendReviewComponent,
    EmailSendComponent,
    EmailSendReviewComponent,
    EmailSendTemplateComponent,
    EmailPreviewComponent,
    DialogEmailCropperComponent,
    CommunicationReportComponent,
    UnsubscribeComponent,
    UnsubscribeConfirmationComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CrmRoutingModule,
    FlexLayoutModule,
    CardModule,
    TextfieldModule,
    ButtonModule,
    DividerModule,
    IconModule,
    TableModule,
    CheckboxModule,
    DraganddropModule,
    StopPropagationModule,
    TreeModule,
    DialogModule,
    ToastModule,
    CKEditorModule,
    ColorPickerModule,
    NgxDaterangepickerMd.forRoot(),
    TagInputModule,
    ImageCropperModule,
    TooltipModule,
    NgxChartsModule,
    PipesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },

    HttpClient,
    TokenService,
    UserService,
    CustomerService,
    CommunicationTemplateService,
    CommunicationInstanceService,
    VoucherService,
    CrmCategoryService,
    CrmDashboardService,
    UnsubscribeService,

  ],
  entryComponents: [
    DialogConfirmationComponent,
    DialogDashboardGroupsComponent,
    VoucherCreateComponent,
    VoucherUpdateComponent,
  ],
  bootstrap: [CrmComponent],
})
export class CrmModule { }
