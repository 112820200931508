export const message = {
    SIGNIN_ERROR: 'Erro de autenticação.',

    EMAIL_CREATE_SUCCESS: 'Template de email criado com sucesso.',
    EMAIL_UPDATE_SUCCESS: 'Template de email atualizado com sucesso.',
    EMAIL_REMOVE_SUCCESS: 'Template de email removido com sucesso.',
    EMAIL_SEND_SUCCESS: 'Email enviado para a fila de disparo.',
    EMAIL_TEST_SUCCESS: 'Email de teste enviado com sucesso.',

    SMS_CREATE_SUCCESS: 'Template de SMS criado com sucesso.',
    SMS_UPDATE_SUCCESS: 'Template de SMS atualizado com sucesso.',
    SMS_REMOVE_SUCCESS: 'Template de SMS removido com sucesso.',
    SMS_SEND_SUCCESS: 'SMS enviado para a fila de disparo.',
    SMS_TEST_SUCCESS: 'SMS de teste enviado com sucesso.',

    VOUCHER_CREATE_SUCCESS: 'Voucher criado com sucesso.',
    VOUCHER_UPDATE_SUCCESS: 'Voucher atualizado com sucesso.',

    ERROR_DEFAULT: 'Ocorreu um erro. Verifique sua conexão com a internet.',
    ERROR_TEST_COOLDOWN: 'O intervalo mínimo para envio de testes é 30 segundos.',
}