import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {

  constructor(private router: Router, private app: AppComponent) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        const { url } = event
        
        /*
        if (url !== '/signin')
          this.app.checkSession()
        */

        app.setNavigation(event.url)
      }

    })
  }

  ngOnInit(): void {
  }

}
