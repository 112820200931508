import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { TokenService } from 'src/app/services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'crm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.responsive.scss']
})
export class HeaderComponent implements OnInit {

  public loggedUser
  public venues
  public currentVenue
  public showSplitMenu: boolean = false

  constructor(
    public app: AppComponent,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    let currentVenue = await this.tokenService.getCurrentVenue()

    this.loggedUser = await this.tokenService.getUser()
    this.venues = await this.tokenService.getVenues()
    this.currentVenue = currentVenue || this.venues[0]
  }

  toggleSplitMenu() {
    this.showSplitMenu = !this.showSplitMenu
  }

  openSplitMenu() {
    this.showSplitMenu = true
  }

  closeSplitMenu() {
    this.showSplitMenu = false
  }

  async onVenueSelect(venue) {
    this.tokenService.setCurrentVenue(venue)
    this.currentVenue = await this.tokenService.getCurrentVenue()
    this.closeSplitMenu()

    this.app.reload()
  }

}
