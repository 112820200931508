import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplashscreenComponent } from './pages/splashscreen/splashscreen.component'
import { SigninComponent } from './pages/signin/signin.component'
import { SignupComponent } from './pages/signup/signup.component'

const routes: Routes = [
  // { path: 'splashscreen', component: SplashscreenComponent },
  { path: 'signin', component: SigninComponent },
  // { path: 'signup', component: SignupComponent },
  { path: '', redirectTo: '/signin', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
