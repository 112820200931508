import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { SmsComponent } from './pages/sms/sms.component'
import { EmailComponent } from './pages/email/email.component'
import { CrmComponent } from './crm.component'
import { SmsCreateComponent } from './pages/sms-create/sms-create.component'
import { SmsUpdateComponent } from './pages/sms-update/sms-update.component'
import { EmailCreateComponent } from './pages/email-create/email-create.component'
import { EmailUpdateComponent } from './pages/email-update/email-update.component'
import { CustomerComponent } from './pages/customer/customer.component'
import { CustomerDetailComponent } from './pages/customer-detail/customer-detail.component'
import { VoucherComponent } from './pages/voucher/voucher.component'
import { SmsSendComponent } from './pages/sms-send/sms-send.component'
import { EmailSendComponent } from './pages/email-send/email-send.component'
import { CommunicationReportComponent } from './pages/communication-report/communication-report.component'
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component'
import { UnsubscribeConfirmationComponent } from './pages/unsubscribe-confirmation/unsubscribe-confirmation.component'

const chieldRoutes: Routes = [
	{
		path: '',
		component: CrmComponent,
		children: [
			{
				path: 'dashboard',
				component: DashboardComponent,
				data: { title: 'Dashboard' },
			},

			{
				path: 'sms',
				component: SmsComponent,
				data: { title: 'SMS' },
			},

			{
				path: 'sms/create',
				component: SmsCreateComponent,
				data: { title: 'SMS' },
			},

			{
				path: 'sms/update/:_id',
				component: SmsUpdateComponent,
				data: { title: 'SMS' },
			},

			{
				path: 'sms/send',
				component: SmsSendComponent,
				data: { title: 'SMS' },
			},

			{
				path: 'email',
				component: EmailComponent,
				data: { title: 'Email' },
			},

			{
				path: 'email/create',
				component: EmailCreateComponent,
				data: { title: 'Email' },
			},

			{
				path: 'email/update/:_id',
				component: EmailUpdateComponent,
				data: { title: 'Email' },
			},

			{
				path: 'email/send',
				component: EmailSendComponent,
				data: { title: 'Email' },
			},

			{
				path: 'communication-report',
				component: CommunicationReportComponent,
				data: { title: 'Disparos' }
			},

			{
				path: 'customer',
				component: CustomerComponent,
				data: { title: 'Clientes' }
			},

			{
				path: 'customer',
				component: CustomerComponent,
				data: { title: 'Clientes' },
			},

			{
				path: 'customer/:_id',
				component: CustomerDetailComponent,
				data: { title: 'Clientes' },
			},

			{
				path: 'voucher',
				component: VoucherComponent,
				data: { title: 'Voucher' },
			},
		],
	},
]

const routes: Routes = [
	{
		path: 'unsubscribe/email/:encryptedEmail',
		component: UnsubscribeComponent,
		data: { title: 'Descadastrar' },
	},
	{
		path: 'unsubscribe/confirmation',
		component: UnsubscribeConfirmationComponent,
		data: { title: 'Descadastro Confirmado' },
	},
]

@NgModule({
	imports: [RouterModule.forChild(chieldRoutes), RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class CrmRoutingModule { }
