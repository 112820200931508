import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import * as ClassicEditor from '../../../components/@ckeditor/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { TgDialog } from '../../../components/dialog/dialog'
import { DialogConfirmationComponent } from '../../components/dialog-confirmation/dialog-confirmation.component'
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-email-create',
  templateUrl: './email-create.component.html',
  styleUrls: ['./email-create.component.scss', './email-create.responsive.scss']
})
export class EmailCreateComponent implements OnInit {

  public loading: boolean = false

  public emailForm: FormGroup;
  public Editor = ClassicEditor;
  public EditorConfig = {
    toolbar: [
      'heading',
      '|',
      'alignment',
      'fontFamily',
			'fontColor',
      'bold',
      'italic',
      'link',
      '|',
			'bulletedList',
			'numberedList',
			'|',
			'indent',
			'outdent',
			'|',
			'insertTable',
			'undo',
			'redo'
    ]
  }

  public headerBackground: string = '#ffffff'
  public bodyBackground: string = '#ffffff'
  public buttonColor: string = '#666666'
  public buttonBackground: string = '#ffffff'
  public buttonBorder: string = '#666666'

  public message = ''

  logoButtonColor: string = ''
  spotlightButtonColor: string = ''

  @ViewChild('logoWrapper') logoWrapper: ElementRef;
  @ViewChild('spotlightInput') spotlightInput: ElementRef;
  @ViewChild('logo') logo: ElementRef;
  @ViewChild('spotlight') spotlight: ElementRef;
  @ViewChild('logoInput') logoInput: ElementRef;

  constructor(
    private router: Router,
    public app: AppComponent,
    private communicationTemplateService: CommunicationTemplateService,
    private dialog: TgDialog,
    private tokenService: TokenService,
    private formBuilder: FormBuilder,
    private toast: TgToast,
  ) {
    this.emailForm = this.formBuilder.group({
      title: ['', [Validators.required]],

      details: this.formBuilder.group({
        headerBackground: [this.headerBackground, [Validators.required]],
        headerImage: ['', [Validators.required]],
        bodyImage: ['', [Validators.required]],
        bodyBackground: [this.bodyBackground, [Validators.required]],
        bodyText: ['', []],
        buttonText: ['', []],
        buttonLink: ['', []],
        buttonColor: [this.buttonColor, []],
        buttonBackground: [this.buttonBackground, []],
        buttonBorder: [this.buttonBorder, []]
      })
    });
  }

  ngOnInit(): void {
  }

  colorPickerChange(formControlName, color) {
    this.emailForm.get('details').get(formControlName).setValue(color)
  }

  headerImageChange(headerImage) {
    this.emailForm.get('details').get('headerImage').setValue(headerImage)
  }

  bodyImageChange(bodyImage) {
    this.emailForm.get('details').get('bodyImage').setValue(bodyImage)
  }

  ckeditorChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.emailForm.get('details').get('bodyText').setValue(data)
  }

  validate() {
    const { bodyText, buttonText, buttonLink } = this.emailForm.get('details').value

    if (!bodyText) {
      this.confirmation('Seu template não possui <b>TEXTO</b>. Deseja enviar assim mesmo?')
    } else if (!buttonText) {
      this.confirmation('Seu template não possui <b>BOTÃO</b>. Deseja enviar assim mesmo?')
    } else if (!buttonLink) {
      this.confirmation('Seu <b>BOTÃO</b> não possui <b>URL</b>. Deseja enviar assim mesmo?')
    } else {
      this.save()
    }
  }

  confirmation(message) {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '250px',
      height: 'auto',
      data: { title: 'Template de email', message },
    });

    dialogRef.afterClosed.subscribe(confirm => {
      if (confirm) this.save()
    });
  }

  async save() {
    if (this.emailForm.invalid) return false

    this.loading = true

    const { headerImage, bodyImage } = this.emailForm.get('details').value
    let headerImageBlob
    let bodyImageBlob
    let data

    try {
      if (headerImage) headerImageBlob = await this.communicationTemplateService.blob(headerImage, 'temp-image.jpg', 'crm/').toPromise() 
      if (bodyImage) bodyImageBlob = await this.communicationTemplateService.blob(bodyImage, 'temp-image.jpg', 'crm/').toPromise() 
    } catch (error) {
      this.saveError()
    }

    data = {
      ...this.emailForm.value,
      type: 'email',
      venue: this.tokenService.getCurrentVenue()._id
    }

    data.details.headerImage = headerImageBlob ? `/${headerImageBlob.blobUrl}` : null 
    data.details.bodyImage = bodyImageBlob ? `/${bodyImageBlob.blobUrl}` : null

    this.communicationTemplateService.insert(data).subscribe(
      data => this.saveSuccess(),
      error => this.saveError()
    )
  }

  saveSuccess() {
    this.loading = false
    this.toast.show({ text: message.EMAIL_CREATE_SUCCESS, type: 'success' })
    this.router.navigate(['/email'])
  }

  saveError() {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
