<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="24px">

  <div fxLayout="row">
    <tg-button routerLink="/customer">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <tg-icon src="../../../assets/icons/back.svg" [color]="app.color.PRIMARY"></tg-icon>
        <span>voltar</span>
      </div>
    </tg-button>
  </div>

  <tg-card widht="100%" padding="24px">
    <div fxLayout="column" fxLayoutGap="32px">

      <div fxLayout="column" fxLayoutGap="24px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <div class="avatar"></div>

          <div fxFlex fxLayout="column" fxLayoutGap="4px">
            <span class="title">{{customer?.name}} {{customer?.lastName}}</span>
            <span class="subtitle">{{customer?.email}}</span>
            <span class="subtitle">{{customer?.phone}}</span>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="8px">
          <tg-button>Editar perfil</tg-button>
          <tg-button>Excluir perfil</tg-button>
          <tg-button>Enviar SMS</tg-button>
          <tg-button>Enviar EMail</tg-button>
        </div>
      </div>

      <tg-divider></tg-divider>

      <div fxLayout="column" fxLayoutGap="24px">
        <div fxLayout="column">
          <span>Agrupamentos deste cliente</span>
          <span class="groups">Reservas canceladas pelo client, Reservas pelo Facebook, Reservas pelo Instagram, Reservas pelo Google</span>
        </div>

        <div fxLayout="column">
          <span class="title">Tipo de cliente</span>
          <span>{{customer?.type}}</span>
        </div>

        <div fxLayout="column">
          <span class="title">Preferências</span>
          <span>{{customer?.preferences}}</span>
        </div>

        <div fxLayout="column">
          <span class="title">Obeservações</span>
          <span>{{customer?.observations}}</span>
        </div>
      </div>

      <div class="summary" fxLayout="column">
        <div class="header" fxLayout="row">
          <span>Resumo deste cliente em seu restaurante</span>
        </div>

        <div class="content" fxLayout="row">
          <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
            <tg-icon src="../../../assets/icons/walk.svg" size="48px" [color]="app.color.PRIMARY"></tg-icon>
            <span>Entradas diretas</span>
            <span class="kpi">{{customer.summary?.organic}}</span>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
            <tg-icon src="../../../assets/icons/pasta.svg" size="48px" [color]="app.color.PRIMARY"></tg-icon>
            <span>Reservas</span>
            <span class="kpi">{{customer.summary?.reservation}}</span>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
            <tg-icon src="../../../assets/icons/watch.svg" size="48px" [color]="app.color.PRIMARY"></tg-icon>
            <span>Fila de espera</span>
            <span class="kpi">{{customer.summary?.waitlist}}</span>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
            <tg-icon src="../../../assets/icons/thumb_down.svg" size="48px" [color]="app.color.PRIMARY"></tg-icon>
            <span>Não compareceu</span>
            <span class="kpi">{{customer.summary?.notattended}}</span>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
            <tg-icon src="../../../assets/icons/close.svg" size="48px" [color]="app.color.PRIMARY"></tg-icon>
            <span>Canceladas</span>
            <span class="kpi">{{customer.summary?.canceled}}</span>
          </div>
        </div>
      </div>

    </div>
  </tg-card>

</div>