<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="42px" (click)="setShowFilters(false)">

  <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="16px">
    <span class="title">Você {{emails.length ? 'tem ' + emails.length : 'não tem'}} {{emails && emails.length === 1 ? 'email' : 'emails'}} pelo Tagme CRM.</span>

    <div fxFlex></div>

    <div fxLayout="row" class="relative">
      <tg-button type="outline" width="150px" (click)="toggleFilters()" tg-stop-propagation>
        <div fxLayout="row" fxLayoutAlign="center center">
          <tg-icon src="../../../assets/icons/filter.svg" size="24px" [color]="app.color.PRIMARY"></tg-icon>
          <span>Filtros</span>
        </div>
      </tg-button>

      <div fxFlex fxHide.xs></div>

      <!-- FILTERS
      ================================================== -->
      <div fxLayout="column" fxLayoutGap="8px" class="filter-selection" [ngClass]="{'hide': !showFilters}">
        <div *ngFor="let filter of filters" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <input type="checkbox" [(ngModel)]="filter.selected" (ngModelChange)="selectedFiltersChange(filter)" />
          <span>{{ filter.name }}</span>
        </div>
      </div>

      <tg-button type="raised" routerLink="/email/create">+ Criar template de email</tg-button>
    </div>

    <div fxLayout="row" fxLayoutGap="8px">
      <div *ngFor="let filter of selectedFilters" class="filter-wrapper">
        <tg-textfield
          [label]="filter.name"
          [type]="filter.type"
          [(value)]="filter.value"
          (valueChange)="filterChange()"></tg-textfield>
      </div>
    </div>
  </div>

  <tg-table
    fxHide.xs
    [showPlaceholder]="!emails.length"
    placeholder="Você ainda não criou um template de email pelo Tagme CRM."
    [loading]="loading"
    animationSrc="../../../assets/animations/food.json"
    animationSpeed="4">
    <thead>
      <tr>
        <th>Título</th>
        <th>Conteúdo</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of emails" (click)="openEmailDialiog(row)">
        <td>{{ row.title }}</td>
        <td end>{{ row.sent }}</td>
        
        <td end>
          <tg-button (click)="gotoEdit(row._id)">Editar</tg-button>
          <tg-button type="raised" (click)="gotoSend(row._id)">Enviar</tg-button>
        </td>
      </tr>
    </tbody>
  </tg-table>

  <div fxHide.gt-xs fxShow.xs fxFlex fxLayout="column" fxLayoutGap="16px" class="scroll-y padding-8">
    <tg-card *ngFor="let row of emails" width="100%" padding="16px" (click)="openEmailDialiog(row)">
      <div fxLayout="column" fxLayoutGap="16px">
        <span class="name">{{row.title}}</span>

        <div fxLayout="row">
          <span class="label" fxFlex>Enviados:</span>
          <span class="sent">{{ row.sent }}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
          <tg-button fxFlex width="100%" (click)="gotoEdit(row._id)">Editar</tg-button>
          <tg-button fxFlex width="100%" type="raised" (click)="gotoSend(row._id)">Enviar</tg-button>
        </div>
      </div>
    </tg-card>
  </div>

</div>