import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { ValidationService } from 'src/app/services/validation.service';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-email-send-review',
  templateUrl: './email-send-review.component.html',
  styleUrls: ['./email-send-review.component.scss']
})
export class EmailSendReviewComponent implements OnInit {

  public loading: boolean = false

  public template: any
  public emailForm: any
  public email: any
  public color: any
  public count: any
  public environment: any
  public testForm: FormGroup;

  public headerImage
  public bodyImage

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    private communicationTemplateService: CommunicationTemplateService,
    private formBuilder: FormBuilder,
    private toast: TgToast,
  ) {
    const { data } = this.config

    this.template = data.template
    this.emailForm = data.emailForm
    this.email = data.email
    this.color = data.color
    this.count = data.count
    this.environment = data.environment

    this.testForm = this.formBuilder.group({
      destination: ['', [Validators.required, ValidationService.email]],
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const { data } = this.config

      this.headerImage = `${data.environment.BLOB_HOST}${data.email.details.headerImage}`
      this.bodyImage = `${data.environment.BLOB_HOST}${data.email.details.bodyImage}`
    }, 1)
  }

  sendTest() {
    const body = {
      ...this.testForm.value,
      template: this.template
    }

    if (this.testForm.invalid) return false

    this.loading = true

    this.communicationTemplateService.sendTest(body).subscribe(
      data => this.sendTestSuccess(),
      error => this.sendTestError(error)
    )
  }

  send() {
    const body = {
      aggregations: this.emailForm.aggregations.map(d => d.value),
      startDate: this.emailForm.dateRange.startDate._d,
      endDate: this.emailForm.dateRange.endDate._d,
      template: this.template
    }

    this.loading = true

    if (this.emailForm.type === 'group') {
      this.communicationTemplateService.sendtemplate(body).subscribe(
        data => this.sendSuccess(),
        error => this.error()
      )
    }
  }

  sendTestSuccess() {
    this.loading = false
    this.toast.show({ text: message.EMAIL_TEST_SUCCESS, type: 'success' })
  }

  sendTestError(error) {
    this.loading = false

    switch (error.status) {
      case 403:
        this.toast.show({ text: message.ERROR_TEST_COOLDOWN, type: 'error' })
        break
      default:
        this.error()
    }
  }

  sendSuccess() {
    this.loading = false
    this.toast.show({ text: message.EMAIL_SEND_SUCCESS, type: 'success' })
    this.dialog.close({ action: 'SUCCESS' })
  }

  cancel(): void {
    this.dialog.close({ action: 'CANCEL' })
  }

  error() {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
    this.dialog.close({ action: 'ERROR' })
  }

}
