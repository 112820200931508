<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="42px" (click)="setShowFilters(false)">

  <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="16px">
    <span class="title">Você {{smsList.length ? 'tem ' + smsList.length : 'não tem'}} {{smsList && smsList.length === 1 ? 'template' : 'templates'}} de sms pelo Tagme CRM.</span>

    <div fxLayout="row" class="relative">
      <tg-button type="outline" width="150px" (click)="toggleFilters()" tg-stop-propagation>
        <div fxLayout="row" fxLayoutAlign="center center">
          <tg-icon src="../../../assets/icons/filter.svg" size="24px" [color]="app.color.PRIMARY"></tg-icon>
          <span>Filtros</span>
        </div>
      </tg-button>

      <div fxFlex fxHide.xs></div>

      <!-- FILTERS
      ================================================== -->
      <div fxLayout="column" fxLayoutGap="8px" class="filter-selection" [ngClass]="{'hide': !showFilters}">
        <div *ngFor="let filter of filters" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <input type="checkbox" [(ngModel)]="filter.selected" (ngModelChange)="selectedFiltersChange(filter)" />
          <span>{{ filter.name }}</span>
        </div>
      </div>

      <tg-button type="raised" routerLink="/sms/create" [disabled]="loading">+ Criar template de SMS</tg-button>
    </div>

    <div fxLayout="row" fxLayoutGap="8px">
      <div *ngFor="let filter of selectedFilters" class="filter-wrapper">
        <tg-textfield
          [label]="filter.name"
          [type]="filter.type"
          [(value)]="filter.value"
          (valueChange)="filterChange()"></tg-textfield>
      </div>
    </div>

  </div>

  <tg-table fxHide.xs [showPlaceholder]="!smsList.length"
    placeholder="Você ainda não criou um template de SMS pelo Tagme CRM." [loading]="loading"
    animationSrc="../../../assets/animations/food.json" animationSpeed="4">
    <thead>
      <tr>
        <th>Nome do SMS</th>
        <th>Texto</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of smsList" (click)="openSMSDialiog(row)">
        <td>{{ row.title }}</td>
        <td>{{ row.text }}</td>

        <td end fxLayout="row" fxLayoutAlign="center center">
          <tg-button tg-stop-propagation (click)="gotoEdit(row._id)">Editar</tg-button>
          <tg-button tg-stop-propagation type="raised" (click)="gotoSend(row._id)">Enviar</tg-button>
        </td>
      </tr>
    </tbody>
  </tg-table>

  <div fxHide.gt-xs fxShow.xs fxFlex fxLayout="column" fxLayoutGap="16px" class="scroll-y padding-8">
    <tg-card *ngFor="let row of smsList" width="100%" padding="16px" (click)="openSMSDialiog(row)">
      <div fxLayout="column" fxLayoutGap="16px">
        <span class="name">{{row.title}}</span>

        <div fxLayout="column" fxLayoutGap="4px">
          <span class="label">Texto:</span>
          <span class="text">{{ row.text }}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
          <tg-button tg-stop-propagation fxFlex width="100%" (click)="gotoEdit(row._id)">Editar</tg-button>
          <tg-button tg-stop-propagation fxFlex width="100%" type="raised" (click)="gotoSend(row._id)">Enviar
          </tg-button>
        </div>
      </div>
    </tg-card>
  </div>

</div>