<table [ngStyle]="{'width': width}" [ngClass]="{'tbody-hide': loading}">
  <ng-content></ng-content>
</table>

<div class="placeholder" *ngIf="showPlaceholder && placeholder && !loading">
  <span>{{placeholder}}</span>
</div>

<div class="loading" [ngClass]="{'hidden': !loading && animationSrc}">
  <div class="lottie"></div>
</div>