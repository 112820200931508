<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="42px">

	<div fxLayout="column" fxLayout.xs="column" fxLayoutGap="16px">
		<div fxLayout="row" fxLayoutAlign="start center">
			<span fxFlex class="title">Você {{pagination.count ? 'tem ' + pagination.count : 'não tem'}} {{pagination.count === 1 ? 'disparo' : 'disparos'}} pelo Tagme CRM.</span>

			<tg-button type="outline" (click)="exportCSV()">
				<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
					<tg-icon src="../../../assets/icons/file.svg" [color]="app.color.PRIMARY"></tg-icon>
					<span>Exportar</span>
				</div>
			</tg-button>
		</div>

    <div fxLayout="row" fxLayoutGap="16px">
      <div fxLayout="column" fxFlex fxLayoutGap="32px">

        <div fxLayout="row" fxFlex fxLayoutGap="8px">
          <div fxLayout="column" fxFlex fxLayoutGap="4px">
            <span>Data:</span>

            <div class="date-wrapper" fxLayou="row" fxLayoutAlign="start center" fxFlex.xs>
              <tg-icon src="../../../assets/icons/date_range.svg" [color]="app.color.PRIMARY"></tg-icon>

              <input
                fxFlex
                type="text"
                class="date"
                ngxDaterangepickerMd
                [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}"
                [(ngModel)]="date"
                (datesUpdated)="getInstances()"
                [disabled]="loading" />
            </div>
          </div>

          <div fxLayout="column" fxFlex fxLayoutGap="4px">
            <span>Tipo:</span>

            <select fxFlex [(ngModel)]="filter.type" (ngModelChange)="getInstances()">
              <option value="">Todos</option>
              <option value="sms">SMS</option>
              <option value="email">Email</option>
            </select>
          </div>
        </div>

        <div fxLayout="row" fxFlex fxLayoutGap="8px">
          <div fxLayout="column" fxFlex fxLayoutGap="4px">
            <span>Template:</span>

            <select fxFlex [(ngModel)]="filter.templateId" (ngModelChange)="getInstances()">
              <option value="">Todos</option>
              <option *ngFor="let template of templates" [value]="template._id">{{ template.title }}</option>
            </select>
          </div>

          <div fxLayout="column" fxFlex fxLayoutGap="4px">
            <span>Teste:</span>

            <select fxFlex [(ngModel)]="filter.test" (ngModelChange)="getInstances()">
              <option value="">Todos</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxFlex fxLayoutGap="4px">
        <ngx-charts-advanced-pie-chart [results]="rate" label="Disparos"></ngx-charts-advanced-pie-chart>
      </div>
    </div>

    <!--
    <div fxLayout="row" fxLayoutGap="16px">
			<div fxLayout="column" fxFlex fxLayoutGap="4px">
				<span>Data:</span>

				<div class="date-wrapper" fxLayou="row" fxLayoutAlign="start center" fxFlex.xs>
					<tg-icon src="../../../assets/icons/date_range.svg" [color]="app.color.PRIMARY"></tg-icon>

					<input
						fxFlex
						type="text"
						class="date"
						ngxDaterangepickerMd
						[locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}"
						[(ngModel)]="date"
						(datesUpdated)="getInstances()"
						[disabled]="loading" />
				</div>
			</div>

      <div fxLayout="column" fxFlex fxLayoutGap="4px">
				<span>Tipo:</span>

				<select fxFlex [(ngModel)]="filter.type" (ngModelChange)="getInstances()">
					<option value="">Todos</option>
					<option value="sms">SMS</option>
					<option value="email">Email</option>
				</select>
			</div>

			<div fxLayout="column" fxFlex fxLayoutGap="4px">
				<span>Template:</span>

				<select fxFlex [(ngModel)]="filter.templateId" (ngModelChange)="getInstances()">
					<option value="">Todos</option>
					<option *ngFor="let template of templates" [value]="template._id">{{ template.title }}</option>
				</select>
			</div>

			<div fxLayout="column" fxFlex fxLayoutGap="4px">
				<span>Teste:</span>

				<select fxFlex [(ngModel)]="filter.test" (ngModelChange)="getInstances()">
					<option value="">Todos</option>
					<option value="true">Sim</option>
					<option value="false">Não</option>
				</select>
			</div>
		</div>
    -->

    <!--
		<div fxLayout="row" fxLayoutGap="16px">
			<div fxLayout="column" fxFlex fxLayoutGap="4px">
				<span>Destinatário:</span>

				<input
					type="text"
					class="padding-8"
					[(ngModel)]="filter.destination"
					(ngModelChange)="filterChange()">
			</div>

			<div fxLayout="column" fxFlex fxLayoutGap="4px">
				<span>Status:</span>

				<select fxFlex class="padding-8" [(ngModel)]="filter.status" (ngModelChange)="getInstances()">
					<option value="">Todos</option>
					<option value="Entregue">Entregue</option>
					<option value="Aberto">Aberto</option>
					<option value="Erro">Erro</option>
				</select>
			</div>

			<div fxFlex></div>
			<div fxFlex></div>
		</div>
    -->

	</div>

	<tg-table
    fxHide.xs
    [showPlaceholder]="!instances.length"
    placeholder="Você ainda não tem disparos pelo Tagme CRM."
    [loading]="loading"
    animationSrc="../../../assets/animations/food.json"
    animationSpeed="4">
    <thead>
      <tr>
        <th>Data</th>
        <th>Tipo</th>
        <th>Template</th>
        <th>Teste</th>
        <th>Destinatário</th>
        <th>Status</th>
      </tr>
    </thead>

    <tbody *ngIf="!loading">
      <tr *ngFor="let row of instances">

        <td style="text-align: center">{{ row.queueDate | date:'dd/MM/yyyy' }}</td>
        <td style="text-align: center">{{ row.type }}</td>
        <td style="text-align: center">{{ row.templateTitle ? row.templateTitle : '-' }}</td>
        <td style="text-align: center">{{ row.test ? 'SIM' : 'NÃO' }}</td>
        <td style="text-align: center">{{ row.destination || row.target.destination || row.target }}</td>
        <td style="text-align: center">{{ row.status }}</td>
      </tr>
    </tbody>
  </tg-table>

  <!--
  <div fxHide.gt-xs fxShow.xs fxFlex fxLayout="column" fxLayoutGap="16px" class="scroll-y padding-8">
    <tg-card *ngFor="let row of emails" width="100%" padding="16px" (click)="openEmailDialiog(row)">
      <div fxLayout="column" fxLayoutGap="16px">
        <span class="name">{{row.title}}</span>

        <div fxLayout="row">
          <span class="label" fxFlex>Enviados:</span>
          <span class="sent">{{ row.sent }}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
          <tg-button fxFlex width="100%" (click)="gotoEdit(row._id)">Editar</tg-button>
          <tg-button fxFlex width="100%" type="raised" (click)="gotoSend(row._id)">Enviar</tg-button>
        </div>
      </div>
    </tg-card>
	</div>
	-->

	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
		<div fxLayout="row" fxLayoutAlign="center center" class="paginator">
			<tg-icon src="../../../assets/icons/first_page.svg" [color]="app.color.PRIMARY" (click)="paginationFirst()"></tg-icon>
			<tg-icon src="../../../assets/icons/chevron_left.svg" [color]="app.color.PRIMARY" (click)="paginationBack()"></tg-icon>

			<span>{{ pagination.currentPage }} de {{ pagination.total }}</span>

			<tg-icon src="../../../assets/icons/chevron_right.svg" [color]="app.color.PRIMARY" (click)="paginationNext()"></tg-icon>
			<tg-icon src="../../../assets/icons/last_page.svg" [color]="app.color.PRIMARY" (click)="paginationLast()"></tg-icon>
		</div>

		<select [(ngModel)]="dataOptions.limit" (ngModelChange)="paginationChange()">
			<option [value]="10">10</option>
			<option [value]="25">25</option>
			<option [value]="50">50</option>
			<option [value]="50">100</option>
			<option [value]="50">150</option>
		</select>
	</div>

</div>
