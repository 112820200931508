import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service'
import { AppComponent } from 'src/app/app.component';
import * as Lottie from 'lottie-web'
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss', 'customer.responsive.scss']
})
export class CustomerComponent implements OnInit {

  public loading: boolean = false
  public lottie: any = Lottie
  public customers = []
  public groupedCustomers = []

  public count = 0

  constructor(
    private app: AppComponent,
    private router: Router,
    private customerService: CustomerService,
    private toast: TgToast,
  ) { }

  ngOnInit(): void {
    this.getCustomers()
  }

  getCustomers() {
    this.customerService.find(
      { },
      { name: 1, lastName: 1, email: 1, phone: 1 },
      { sort: 'name' }
    ).subscribe(
      data => this.setCustomers(data),
      error => this.error(error)
    )
  }

  setCustomers(data) {
    this.customers = data
    this.count = data.length

    this.groupedCustomers = Object.values(this.customers.reduce((r, e) => {
      let group = e.name[0];

      if (!r[group]) r[group] = { group, children: [e] }
      else r[group].children.push(e);

      return r;
    }, {}))

    this.loading = false
  }

  gotoDetail(_id) {
    this.router.navigate([`customer/${_id}`])
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
