import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CrmCategoryService } from 'src/app/services/crmcategory.service';
import { TgDialog } from 'src/app/components/dialog/dialog';
import { DialogDashboardGroupsComponent } from '../../components/dialog-dashboard-groups/dialog-dashboard-groups.component'
import { SmsSendTemplateComponent } from '../sms-send-template/sms-send-template.component'
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import * as moment from 'moment'
import { SmsSendReviewComponent } from '../sms-send-review/sms-send-review.component';
import { DialogConfirmationComponent } from '../../components/dialog-confirmation/dialog-confirmation.component';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-sms-send',
  templateUrl: './sms-send.component.html',
  styleUrls: ['./sms-send.component.scss', './sms-send.responsive.scss']
})
export class SmsSendComponent implements OnInit {

  public loading: boolean = false

  public count: { total: 0, phone: 0, email: 0 }

  public categoryIds: Array<any> = []
  public templateId: string
  public startDate: number
  public endDate: number

  public crmCategories: Array<any> = []
  public selected = []
  public smsForm: FormGroup;
  public sms: any
  public textChange: boolean = false

  public dateRange: any = { startDate: moment(), endDate: moment() }

  constructor(
    public app: AppComponent,
    private formBuilder: FormBuilder,
    private crmCategoryService: CrmCategoryService,
    private communicationTemplateService: CommunicationTemplateService,
    public dialog: TgDialog,
    private toast: TgToast,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.smsForm = this.formBuilder.group({
      type: ['group', [Validators.required]],
      aggregations: [[], [Validators.required]],
      dateRange: [{ startDate: moment(), endDate: moment() }, [Validators.required]],
      text: ['', [Validators.required, Validators.minLength(3)]],
    });

    this.activatedRoute.queryParams.subscribe(params => {
      let { categoryIds, templateId, startDate, endDate } = params

      if (categoryIds && !Array.isArray(categoryIds)) this.categoryIds = [categoryIds]
      else this.categoryIds = categoryIds || []

      this.templateId = templateId
      this.startDate = Number(startDate)
      this.endDate = Number(endDate)
    })
  }

  ngOnInit(): void {
    /*
    this.smsForm.get('aggregations').valueChanges.subscribe(value => this.countCustomers())
    this.smsForm.get('dateRange').valueChanges.subscribe(value => this.countCustomers())
    this.smsForm.get('text').valueChanges.subscribe(value => this.textChange = value !== this.sms.text)
    */

    this.init()
  }

  ngAfterContentInit(): void {
  }

  countCustomers() {
    const aggregations = this.smsForm.get('aggregations').value
    const dateRange = this.smsForm.get('dateRange').value
    const { startDate, endDate } = dateRange

    if (!aggregations || !startDate || !endDate)
      return false

    this.loading = true

    this.crmCategoryService.customersCount({
      categoryIds: aggregations.map(d => d.value),
      startDate: startDate._d,
      endDate: endDate._d
    }).subscribe(
      data => this.setCount(data),
      error => this.error(error)
    )
  }

  setCount(count) {
    this.loading = false
    this.count = count
  }

  async init() {
    this.loading = true

    try {
      this.crmCategories = await <any>this.crmCategoryService.find({}, {}, { sort: 'index' }).toPromise()
      this.loadCategories()

      if (this.templateId) {
        this.sms = await this.communicationTemplateService.findById(this.templateId).toPromise()
        this.smsForm.get('text').setValue(this.sms.text)
      }

      if (this.startDate && this.endDate) {
        this.smsForm.get('dateRange').setValue({
          startDate: moment(this.startDate),
          endDate: moment(this.endDate)
        })
      }

      await this.countCustomers()
    } catch (error) {
      this.error(error)
    }

    this.smsForm.get('aggregations').valueChanges.subscribe(value => this.countCustomers())
    this.smsForm.get('dateRange').valueChanges.subscribe(value => this.countCustomers())
    this.smsForm.get('text').valueChanges.subscribe(value => this.textChange = value !== this.sms.text)
  }

  loadCategories() {
    let aggregations = []

    this.crmCategories.forEach(d => d.selected = false)

    this.categoryIds.forEach(_id => {
      let data = this.crmCategories.find(crmCategory => crmCategory._id === _id)

      if (data) {
        data.selected = true

        aggregations.push({
          display: data.name.pt,
          value: data._id
        })
      }
    })

    this.smsForm.get('aggregations').setValue(aggregations)
  }

  openAggregationsDialog() {
    const ref = this.dialog.open(DialogDashboardGroupsComponent, {
      width: 'auto',
      height: 'auto',
      data: { categories: JSON.parse(JSON.stringify(this.crmCategories)) }
    });

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}

      if (action === 'OK' && data) {
        this.categoryIds = data
          .filter(d => d.selected)
          .map(d => d._id)

        this.loadCategories()
      }
    })
  }

  textConfirmation() {
    let ref

    if (this.textChange) {
      ref = this.dialog.open(DialogConfirmationComponent, {
        width: '250px',
        height: 'auto',
        data: { title: 'Atualização de template', message: `Deseja atualizar o texto do template ${this.sms.title}?` },
      });

      ref.afterClosed.subscribe(result => {
        if (result)
          this.confirmUpdate()
      })
    } else {
      this.openReviewDialog()
    }
  }

  async confirmUpdate() {
    try {
      const _id = this.sms._id
      const text = this.smsForm.get('text').value

      this.loading = true
      await this.communicationTemplateService.updateById(_id, { text }).toPromise()
      this.loading = false

      this.openReviewDialog()
    } catch (error) {
      this.error(error)
    }
  }

  onAggregationRemove() {
    this.categoryIds = this.smsForm.get('aggregations').value.map(d => d.value)
    this.loadCategories()
  }

  openTemplatesDialog() {
    const ref = this.dialog.open(SmsSendTemplateComponent, {
      width: 'auto',
      height: 'auto',
      card: true,
      data: {}
    })

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}

      if (action === 'OK' && data && data.sms) {
        this.sms = data.sms
        this.smsForm.get('text').setValue(this.sms.text)
        this.textChange = false
      }
    })
  }

  openReviewDialog() {
    if (this.smsForm.invalid) return false

    const ref = this.dialog.open(SmsSendReviewComponent, {
      width: 'auto',
      height: 'auto',
      card: false,
      data: { template: this.sms._id, sms: this.smsForm.value, color: this.app.color, count: this.count }
    })

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}
    })
  }

  back() {
    this.location.back()
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
