import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { Router } from '@angular/router';
import { TgDialog } from 'src/app/components/dialog/dialog';
import { DialogSmsViewComponent } from '../../components/dialog-sms-view/dialog-sms-view.component';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss', './sms.responsive.scss']
})
export class SmsComponent implements OnInit {

  public loading: boolean = false
  public smsList: Array<any> = []
  public showFilters: boolean = false
  public filterTimeout

  public filters = [
    { type: 'text', selected: false, name: 'Nome do SMS', property: 'title', value: '' },
    { type: 'text', selected: false, name: 'Texto', property: 'text', value: '' }
  ]

  public selectedFilters = []

  constructor(
    public app: AppComponent,
    private communicationTemplateService: CommunicationTemplateService,
    private router: Router,
    public dialog: TgDialog,
    private toast: TgToast
  ) { }

  ngOnInit(): void {
    this.getSmsList()
  }

  selectedFiltersChange(filter) {
    filter.value = ''
    this.selectedFilters = this.filters.filter(d => d.selected)
    this.getSmsList()
  }

  filterChange() {
    clearTimeout(this.filterTimeout)

    this.filterTimeout = setTimeout(() => {
      this.getSmsList()
    }, 500)
  }

  getMatch() {
    const filters = {}

    this.filters.forEach(d => {
      if (d.selected && d.value) filters[d.property] = d.value
    })

    return filters
  }

  getSmsList() {
    this.loading = true
    
    this.communicationTemplateService.findByType(
      'sms',
      this.getMatch(),
      { title: 1, text: 1 },
      { sort: '-created_at', regexp: 1 }
    ).subscribe(
      data => this.setSmsList(data),
      error => this.error(error)
    )
  }

  setSmsList(smsList) {
    this.smsList = smsList
    this.loading = false
  }

  gotoEdit(_id) {
    this.router.navigate([`sms/update/${_id}`])
  }

  gotoSend(_id) {
    this.router.navigate(['/sms/send'], {
      queryParams: { templateId: _id }
    })
  }

  openSMSDialiog(sms) {
    const ref = this.dialog.open(DialogSmsViewComponent, {
      width: 'auto',
      height: 'auto',
      card: false,
      data: { sms, color: this.app.color }
    })

    ref.afterClosed.subscribe(result => {
      const { action } = result || {}

      if (action === 'REMOVE')
        this.remove(sms._id)
    })
  }

  remove(_id) {
    this.loading = true

    this.communicationTemplateService.updateById(_id, { deleted: true })
      .subscribe(
        data => this.removeSuccess(),
        error => this.error(error)
      )
  }

  removeSuccess() {
    this.loading = false
    this.toast.show({ text: message.SMS_REMOVE_SUCCESS, type: 'success' })
    this.getSmsList()
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

  toggleFilters() {
    this.showFilters = !this.showFilters
  }

  setShowFilters(showFilters: boolean) {
    this.showFilters = showFilters
  }

}

// TODO: PERSISTIR FILTROS