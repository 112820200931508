import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanetizerPipe } from './sanetizer.pipe'


@NgModule({
  declarations: [SanetizerPipe],
  exports: [SanetizerPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
