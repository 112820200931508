import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeComponent } from './tree.component';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { TreeContentComponent } from './tree-content/tree-content.component';



@NgModule({
  declarations: [TreeComponent, TreeNodeComponent, TreeContentComponent],
  exports: [TreeComponent, TreeNodeComponent, TreeContentComponent],
  imports: [
    CommonModule
  ]
})
export class TreeModule { }
