import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextfieldComponent } from './textfield.component';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask'

@NgModule({
  declarations: [ TextfieldComponent ],
  exports: [ TextfieldComponent ],
  imports: [ CommonModule, ReactiveFormsModule, NgxMaskModule.forRoot() ]
})

export class TextfieldModule { }
