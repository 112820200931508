import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'tg-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }

}
