import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'tg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {

  @Input() type: string = 'text'
  @Input() color: string = 'primary'
  @Input() backgroundColor: string = ''
  @Input() textColor: string = ''
  @Input() disabled: boolean = false
  @Input() width: string = ''

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    const wrapper = this.el.nativeElement
    const button = this.el.nativeElement.firstElementChild

    if (this.type === 'text') {
      button.classList.add(`background-none`)
      button.classList.add(`text-${this.color}`)
    }

    if (this.type === 'outline') {
      button.classList.add(`background-none`)
      button.classList.add(`text-${this.color}`)
      button.classList.add(`border-${this.color}`)
    }

    if (this.type === 'raised') {
      button.classList.add(`background-${this.color}`)
      button.classList.add(`text-white`)
    }

    if (this.width) {
      wrapper.style.width = this.width
      button.style.width = this.width
    }

  }

}
