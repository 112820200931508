import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { TgToast } from 'src/app/components/toast/toast';
import { CommunicationInstanceService } from 'src/app/services/communicationinstance.service';
import { message,  } from '../../../constants'
import * as moment from 'moment'
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { ExportToCsv } from 'export-to-csv';


@Component({
  selector: 'app-communication-report',
  templateUrl: './communication-report.component.html',
  styleUrls: ['./communication-report.component.scss']
})
export class CommunicationReportComponent implements OnInit {

  public loading: boolean = false
  public loadingLog: boolean = false
  public instances: Array<any> = []
  public rate: Array<any> = []
  public log: Array<any> = []
  public showFilters: boolean = false
  public templates
  public filterTimeout

  public date = { startDate: moment().startOf('month'), endDate: moment().endOf('month') }

  public filter = {
    type: '',
    templateId: '',
    test: '',
    status: '',
    destination: ''
  }

  public dataOptions = {
    skip: 0,
    limit: 10
  }

  public pagination = {
    count: 0,
    currentPage: 1,
    total: 1
  }

  public selectedFilters = []

  constructor(
    public app: AppComponent,
    private toast: TgToast,
    private communicationInstanceService: CommunicationInstanceService,
    private communicationTemplateService: CommunicationTemplateService
  ) { }

  ngOnInit(): void {
    this.communicationTemplateService.find({deleted: false}, {title: 1}).subscribe(
      data => this.setTemplates(data),
      error => this.error(error)
    )

    this.getInstances()
  }

  setTemplates(templates) {
    this.templates = templates
  }

  filterChange() {
    clearTimeout(this.filterTimeout)

    this.filterTimeout = setTimeout(() => {
      this.getInstances()
    }, 500)
  }

  getMatch() {
    let match = {}

    if (this.filter.type) match['type'] = this.filter.type
    if (this.filter.templateId) match['templateId'] = this.filter['templateId']
    if (this.filter.test === 'true' || this.filter.test === 'false') match['test'] = this.filter.test
    if (this.filter.status) match['status'] = this.filter.status
    if (this.filter.destination) match['destination'] = this.filter.destination

    return match
  }

  async getInstances() {
    const startDate = this.date.startDate.toDate();
    const endDate = this.date.endDate.toDate();
    let count
    let instances

    this.loading = true

    if (!startDate || !endDate)
      this.error({})

    try {
      count = await this.communicationInstanceService.countByDate(
        startDate,
        endDate,
        this.getMatch()
      ).toPromise()

      instances = await this.communicationInstanceService.findByDate(
        startDate,
        endDate,
        this.getMatch(),
        {},
        { sort: '-queueDate', ...this.dataOptions }
      ).toPromise()

      instances.data.map(d => d.status = this.parseStatus(d.status))

      this.setPagination(count)
      this.setInstances(instances.data)
      this.setRate(instances.rate)

      this.loading = false
    } catch (error) {
      this.error(error)
    }
  }

  setPagination(count) {
    this.pagination.count = count
    this.pagination.total = Math.ceil(count / this.dataOptions.limit)
  }

  paginationNext() {
    if (this.pagination.currentPage < this.pagination.total) {
      this.pagination.currentPage++
      this.paginationChange()
    }
  }

  paginationBack() {
    if (this.pagination.currentPage > 1) {
      this.pagination.currentPage--
      this.paginationChange()
    }
  }

  paginationFirst() {
    if (this.pagination.currentPage > 1) {
      this.pagination.currentPage = 1
      this.paginationChange()
    }
  }

  paginationLast() {
    if (this.pagination.currentPage < this.pagination.total) {
      this.pagination.currentPage = this.pagination.total
      this.paginationChange()
    }
  }

  paginationChange() {
    this.dataOptions.skip = (this.pagination.currentPage - 1) * this.dataOptions.limit
    this.getInstances()
  }

  parseStatus(status) {
    console.log(status)
    switch (status.toLowerCase()) {
      case 'sent':
        return 'Entregue'
      case 'delivered':
        return 'Entregue'
      case 'success':
        return 'Entregue'
      case 'open':
        return 'Aberto'
      default:
        return status
    }
  }

  setInstances(instances) {
    this.instances = instances
  }

  setRate(rate) {
    this.rate = rate
  }

  async exportCSV() {
    let instances
    let formatedData
    const startDate = this.date.startDate.toDate();
    const endDate = this.date.endDate.toDate();
    const name = `TAGMECRM_ENVIOS_${this.date.startDate.format('yyyy-MM-DD')}_${this.date.endDate.format('yyyy-MM-DD')}`

    const csvExporter = new ExportToCsv({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: name,
      title: name,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });

    this.loading = true

    try {
      instances = await this.communicationInstanceService.findByDate(
        startDate,
        endDate,
        this.getMatch(),
        {},
        { sort: '-queueDate', skip: 0, limit: 999999 } // XGH
      ).toPromise()

      formatedData = instances.data.map(d => {
        return {
          "Data": moment(d.queueDate).format('DD/MM/yyyy'),
          "Tipo": d.type,
          "Template": d.templateTitle,
          "Teste": d.test ? 'SIM' : 'NÃO',
          "Destinatário": d.destination || d.target.destination || d.target,
          "Status": this.parseStatus(d.status),
        }
      })

      csvExporter.generateCsv(formatedData)
    } catch (error) {
      this.error(error)
    }

    this.loading = false
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
