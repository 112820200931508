<div class="wrapper" fxLayout="row">

  <div *ngFor="let button of app.navigation" [routerLink]="button.routerLink" class="button" fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
    <tg-icon
      [src]="button.icon"
      size="20px"
      [color]="!button.selected ? '#666666' : app.color.PRIMARY"></tg-icon>

    <span [ngClass]="{'selected': button.selected}">{{ button.shortText }}</span>
  </div>

</div>