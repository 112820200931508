
import { CommonModule } from '@angular/common';

import { NgModule, ModuleWithProviders } from '@angular/core'
import { OverlayModule } from '@angular/cdk/overlay';

import { ToastComponent } from './toast.component';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-config';

@NgModule({
  declarations: [ToastComponent],
  entryComponents: [ToastComponent],
  exports: [ToastComponent],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: TOAST_CONFIG_TOKEN,
      useValue: { ...defaultToastConfig, ...defaultToastConfig },
    }
  ]
})
export class ToastModule {
  
  
}
