import { Component, OnInit } from '@angular/core';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';

@Component({
  selector: 'app-dialog-dashboard-groups',
  templateUrl: './dialog-dashboard-groups.component.html',
  styleUrls: ['./dialog-dashboard-groups.component.scss', './dialog-dashboard-groups.responsive.scss']
})
export class DialogDashboardGroupsComponent implements OnInit {

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef
  ) {
  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialog.close({action: 'CANCEL'})
  }

  ok(): void {
    this.dialog.close({ action: 'OK', data: this.config.data.categories })
  }

}
