import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tg-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements OnInit {

  @Input() type: string = 'horizontal';
  @Input() margin: string = '4px';

  constructor() { }

  ngOnInit(): void {
  }

}
