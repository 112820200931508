import { Component, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-dialog-email-cropper',
  templateUrl: './dialog-email-cropper.component.html',
  styleUrls: ['./dialog-email-cropper.component.scss']
})
export class DialogEmailCropperComponent implements OnInit {

  ref: string
  event: any
  file: any
  ratio: any
  croppedImage: any

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef
  ) {
    const { data } = this.config

    this.ref = data.ref
    this.event = data.event
    this.file = data.file
    this.ratio = data.ratio || 1
  }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  cancel(): void {
    this.dialog.close({ action: 'CANCEL' })
  }

  ok(): void {
    this.dialog.close({ action: 'OK', data: { ref: this.ref, image: this.croppedImage } })
  }

}
