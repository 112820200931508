<div
  class="wrapper"
  [ngStyle]="
  {
    'padding': padding,
    'background': background,
    'box-shadow': boxShadow,
    'border-radius': radius
  }">

  <ng-content></ng-content>

</div>
