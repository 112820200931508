import { Component, OnInit, ElementRef, Input } from '@angular/core';
import * as Lottie from 'lottie-web'

@Component({
  selector: 'tg-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() width: string = '100%'
  @Input() showPlaceholder: boolean = false
  @Input() placeholder: string = ''

  @Input() loading: boolean = false
  @Input() animationSrc: string = ''
  @Input() animationSpeed: number = 1
  
  public lottie: any = Lottie
  public lottieConfig: Object;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    if (this.animationSrc)
      this.loadLottie()
  }

  loadLottie() {
    this.lottie.loadAnimation({
      container: document.querySelector('.lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.animationSrc,
      resize: 5000
    });

    this.lottie.setSpeed(this.animationSpeed)
  }

}
