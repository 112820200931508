import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../services/app.service'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import * as qs from 'qs';

@Injectable()
export class CommunicationTemplateService extends AppService {

  url: string

  constructor(
    public http: HttpClient,
  ) {
    super('communicationtemplate', http);
    this.url = `${environment.API_HOST}/communicationtemplate`;
  }

  findByType(type = '', match = {}, fields = {}, options = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next([]))

    const querystring = qs.stringify({ match, fields, options }, { encode: false })
    return this.http.get(`${this.url}/${venue}/list/${type}/?${querystring}`)
  }

  blob(imageInBase64: string, imageName: string, folderName: string) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.post(`${this.url}/${venue}/blob`, {
      base64: true,
      file: imageInBase64,
      fileName: imageName,
      folder: folderName,
    })
  }

  sendGroup(body) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.post(`${this.url}/${venue}/send/group`, body)
  }

  sendtemplate(body) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.post(`${this.url}/${venue}/email/send/template`, body)
  }

  sendTest(body) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.post(`${this.url}/${venue}/send/test`, body)
  }

}