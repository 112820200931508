import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../services/app.service'
import { environment } from '../../environments/environment';

@Injectable()
export class CrmDashboardService extends AppService {

  url: string

  constructor(
    public http: HttpClient 
  ) {
    super('crmdashboard', http);
    this.url = `${environment.API_HOST}/crmdashboard`;
  }

}