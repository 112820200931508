import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as qs from 'qs';
import { TokenService } from './token.service';
import { Observable } from 'rxjs/internal/Observable';

export class AppService extends TokenService {

  url: string
  http: HttpClient

  constructor(path, http) {
    super()

    this.url = `${environment.API_HOST}/${path}`
    this.http = http
  }

  insert(body) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.post(`${this.url}/${venue}`, body)
  }

  find(match = {}, fields = {}, options = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next([]))

    const querystring = qs.stringify({ match, fields, options }, { encode: false })
    return this.http.get(`${this.url}/${venue}/?${querystring}`)
  }

  findById(id = '', fields = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next(null))

    const querystring = qs.stringify({ fields }, { encode: false })
    return this.http.get(`${this.url}/${venue}/${id}?${querystring}`)
  }

  count(match = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next(0))

    const querystring = qs.stringify({ match }, { encode: false })
    return this.http.get(`${this.url}/${venue}/count?${querystring}`)
  }

  update(body = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.put(`${this.url}/${venue}`, body)
  }

  updateById(id = '', body) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next({}))

    return this.http.put(`${this.url}/${venue}/${id}`, body)
  }

  remover() { }

}