import { Injectable } from "@angular/core";
@Injectable()
export class TokenService {

  getToken() {
    return localStorage.getItem('auth')
  }

  setToken(token) {
    return localStorage.setItem('auth', token)
  }

  destroyToken() {
    return localStorage.removeItem('auth')
  }

  getUser() {
    let user = localStorage.getItem('user')

    if (!user) return null
    else return JSON.parse(user)
  }

  setUser(user) {
    return localStorage.setItem('user', JSON.stringify(user))
  }

  destroyUser() {
    return localStorage.removeItem('user')
  }

  getVenues() {
    let venues = localStorage.getItem('venues')

    if (!venues) return []
    else return JSON.parse(venues)
  }

  setVenues(venues) {
    return localStorage.setItem('venues', JSON.stringify(venues))
  }

  destroyVenues() {
    return localStorage.removeItem('venues')
  }

  getCurrentVenue() {
    let currentVenue = localStorage.getItem('currentVenue')

    if (!currentVenue) return []
    else return JSON.parse(currentVenue)
  }

  setCurrentVenue(currentVenue) {
    return localStorage.setItem('currentVenue', JSON.stringify(currentVenue))
  }

  destroyCurrentVenue() {
    return localStorage.removeItem('currentVenue')
  }

}