<div class="wrapper" fxLayout="column" fxLayoutGap="24px">

  <div fxLayout="row">
    <span class="title">Atualizar voucher promocional</span>
  </div>

  <tg-divider></tg-divider>

  <form [formGroup]="voucherForm" fxLayout="column" fxLayoutGap="24px">
    <tg-textfield formControlName="name" label="Nome da campanha" placeholder="Ex: aniversário de 5 anos">
    </tg-textfield>

    <div fxLayout="column" fxLayoutGap="8px">
      <span class="label">Tipo do benefício</span>

      <div fxLayout="row">
        <label fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <input type="radio" value="monetario" formControlName="type">
          <span>Monetário</span>
        </label>

        <label fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <input type="radio" value="percentual" formControlName="type">
          <span>Percentual</span>
        </label>

        <label fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <input type="radio" value="amenidade" formControlName="type">
          <span>Amenidade</span>
        </label>
      </div>
    </div>

    <div *ngIf="voucherForm.get('type').value === 'monetario'" fxLayout="row" fxLayoutGap="16px">
      <tg-textfield fxFlex formControlName="value" label="Valor do desconto" placeholder="Ex: R$ 10,00">
      </tg-textfield>

      <tg-textfield fxFlex formControlName="minValue" label="Compra mínima" placeholder="Ex: R$ 50,00">
      </tg-textfield>
    </div>

    <div *ngIf="voucherForm.get('type').value === 'percentual'" fxLayout="column" fxLayoutGap="8px">
      <span class="label">Percentual de desconto</span>
      <select formControlName="value">
        <option *ngFor="let data of percentList" [value]="data.value">{{data.name}}</option>
      </select>
    </div>

    <div *ngIf="voucherForm.get('type').value === 'amenidade'" fxLayout="column" fxLayoutGap="16px">
      <tg-textfield fxFlex formControlName="value" label="Qual presente gostaria de dar para seus clientes?"
        placeholder="Ex: Uma sobremesa grátis ou uma bebida cortesia">
      </tg-textfield>
    </div>

    <div fxLayout="column">
      <span class="label">Utilização do benefício</span>

      <div fxLayout="row">

      </div>

      <div fxLayout="row"></div>
    </div>

    <div fxLayout="column" fxLayoutGap="8px">
      <span class="label">Descrição do benefício</span>

      <textarea formControlName="description" placeholder="Ex: Utilização exclusiva para o aniversário de 5 anos."
        rows="5"></textarea>
    </div>

    <div fxLayout="column" fxLayoutGap="8px">
      <span class="label">Código do voucher (Esse código será enviado para seu cliente)</span>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
        <tg-textfield fxFlex formControlName="voucher" placeholder="Ex: niver5anos">
        </tg-textfield>

        <tg-button type="raised">Código aleatório</tg-button>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="8px">
      <span class="label">Regra de datas para utilização</span>

      <div fxLayout="row" fxLayoutGap="8px">
        <label fxFLex>
          De
          <input formControlName="startDate" [min]="yesterday" class="datepicker" [owlDateTimeTrigger]="startDate" [owlDateTime]="startDate">
          <owl-date-time #startDate [pickerType]="'calendar'"></owl-date-time>
        </label>

        <label fxFLex>
          Até
          <input formControlName="endDate" [min]="this.voucherForm.get('startDate').value || yesterday" class="datepicker" [owlDateTimeTrigger]="endDate" [owlDateTime]="endDate">
          <owl-date-time #endDate [pickerType]="'calendar'"></owl-date-time>
        </label>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <input type="checkbox" id="exceptHolidays" name="exceptHolidays" formControlName="exceptHolidays">
      <label for="exceptHolidays">Exceto finais de semana</label>
    </div>

    <div fxLayout="column" fxLayoutGap="8px">
      <span class="label">Horário para validação</span>

      <div fxLayout="row" fxLayoutGap="8px">
        <select fxFlex formControlName="validationHourStart">
          <option *ngFor="let data of hourList" [value]="data.name">{{data.name}}</option>
        </select>

        <select fxFlex formControlName="validationHourEnd">
          <option *ngFor="let data of hourList" [value]="data.name">{{data.name}}</option>
        </select>
      </div>
    </div>

    <tg-textfield type="number" formControlName="quantity" label="Quantidade de vouchers disponibilizados">
    </tg-textfield>
  </form>
  
  <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="8px">
    <tg-button width="100%" (click)="cancel()">Cancelar</tg-button>
    <tg-button width="100%" type="raised" [disabled]="voucherForm.invalid" (click)="ok()">Atualizar voucher</tg-button>
  </div>

</div>