<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="42px">

  <div fxLayout="row">
    <tg-button (click)="back()">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <tg-icon src="../../../assets/icons/back.svg" [color]="app.color.PRIMARY"></tg-icon>
        <span>voltar</span>
      </div>
    </tg-button>
  </div>

  <div fxLayout="column">
    <div fxLayout="row">
      <h2>SMS Marketing</h2>
    </div>

    <div fxLayout="row">
      <span *ngIf="!loading">total de clientes: <span class="color-primary"></span>{{ count?.total }}</span>
      <span *ngIf="loading" class="color-primary">Calculando</span>
    </div>

    <div fxLayout="row">
      <span *ngIf="!loading"> <span class="color-primary">{{ count?.phone }}</span> {{ count?.phone && count?.phone === 1 ? 'cliente' : 'clientes' }} com telefone cadastrado.</span>
      <span *ngIf="loading" class="color-primary">Calculando</span>
    </div>
  </div>

  <div fxLayout="row">
    <form novalidate [formGroup]="smsForm" fxFlex fxLayout="column" fxLayoutGap="40px">

      <!-- TYPE
      ==================================================-->
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="16px">
        <label fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <input type="radio" value="group" formControlName="type">
          <span>por Agrupamento</span>
        </label>

        <div fxFlex></div>

        <label fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <input type="radio" value="individual" formControlName="type" disabled>
          <span>por Cliente (individual)</span>
        </label>
      </div>

      <!-- CHIPS
      ==================================================-->
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end end">
        <tag-input
          fxFlex
          formControlName='aggregations'
          secondaryPlaceholder="Nenhum agrupamento"
          placeholder="+ Agrupamento"
          [onlyFromAutocomplete]="true"
          (onRemove)="onAggregationRemove()"></tag-input>
        
        <tg-button type="raised" (click)="openAggregationsDialog()" [disabled]="loading">+ Agrupamento</tg-button>
      </div>

      <div fxFlex fxLayout="column" fxLayoutGap="8px">
        <span>No período de:</span>
  
        <div class="date-wrapper" fxLayou="row" fxLayoutAlign="start center" fxFlex.xs>
          <tg-icon src="../../../assets/icons/date_range.svg" [color]="app.color.PRIMARY"></tg-icon>
  
          <input
            fxFlex
            type="text"
            class="date"
            ngxDaterangepickerMd
            [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}"
            formControlName="dateRange" />
        </div>
      </div>

      <!-- TEXT
      ==================================================-->
      <div class="relative" fxLayout="column">
        <div fxLayout="row">
          <span>{{ sms?.title || 'Texto do SMS' }}</span>

          <div fxFlex></div>

          <tg-button (click)="openTemplatesDialog()">Selecionar</tg-button>
        </div>

        <textarea formControlName="text" rows="7" [attr.disabled]="!sms ? '' : null" maxlength="124"></textarea>
        <span class="hint">{{ smsForm.get('text').value.length }} de 124 caracteres.</span>
      </div>

      <tg-button type="raised" width="100%" (click)="textConfirmation()" [disabled]="smsForm.invalid || !sms || loading">Revisar e Enviar SMS</tg-button>

    </form>

    <div fxFlex.gt-md="70" fxFlex.md="50" fxLayout="column" fxHide.xs fxHide.sm></div>
  </div>

</div>