import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import * as ClassicEditor from '../../../components/@ckeditor/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { TgDialog } from '../../../components/dialog/dialog'
import { DialogConfirmationComponent } from '../../components/dialog-confirmation/dialog-confirmation.component'
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TgToast } from 'src/app/components/toast/toast';
import { environment } from 'src/environments/environment';
import { message } from '../../../constants'

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.scss', './email-update.responsive.scss']
})
export class EmailUpdateComponent implements OnInit {

  public loading: boolean = false

  public _id: string;
  public emailForm: FormGroup;
  public Editor = ClassicEditor;
  public EditorConfig = {
    toolbar: [
      'heading',
      '|',
      'alignment',
      'fontFamily',
			'fontColor',
      'bold',
      'italic',
      'link',
      '|',
			'bulletedList',
			'numberedList',
			'|',
			'indent',
			'outdent',
			'|',
			'insertTable',
			'undo',
			'redo'
    ]
  }

  public headerBackground: string = '#ffffff'
  public bodyBackground: string = '#ffffff'
  public buttonColor: string = '#666666'
  public buttonBackground: string = '#ffffff'
  public buttonBorder: string = '#666666'
  public headerImage: string
  public bodyImage: string

  public headerImageChange: boolean = false
  public bodyImageChange: boolean = false

  public message = ''

  logoButtonColor: string = ''
  spotlightButtonColor: string = ''

  @ViewChild('logoWrapper') logoWrapper: ElementRef;
  @ViewChild('spotlightInput') spotlightInput: ElementRef;
  @ViewChild('logo') logo: ElementRef;
  @ViewChild('spotlight') spotlight: ElementRef;
  @ViewChild('logoInput') logoInput: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public app: AppComponent,
    private communicationTemplateService: CommunicationTemplateService,
    private dialog: TgDialog,
    private tokenService: TokenService,
    private formBuilder: FormBuilder,
    private toast: TgToast,
  ) {
    this.emailForm = this.formBuilder.group({
      title: ['', [Validators.required]],

      details: this.formBuilder.group({
        headerBackground: [this.headerBackground, [Validators.required]],
        headerImage: ['', [Validators.required]],
        bodyImage: ['', [Validators.required]],
        bodyBackground: [this.bodyBackground, [Validators.required]],
        bodyText: ['', []],
        buttonText: ['', []],
        buttonLink: ['', []],
        buttonColor: [this.buttonColor, []],
        buttonBackground: [this.buttonBackground, []],
        buttonBorder: [this.buttonBorder, []]
      })
    });

    this.activatedRoute.params.subscribe(params => {
      this.getEmail(params['_id'])
    })
  }

  ngOnInit(): void {
  }

  getEmail(_id) {
    this.loading = true

    this.communicationTemplateService.findById(_id)
      .subscribe(
        data => this.setEmail(data),
        error => this.updateError(error)
      )
  }

  setEmail(email) {
    const { title, details } = email
    
    this.headerImage = `${environment.BLOB_HOST}${details.headerImage}`
    this.bodyImage = `${environment.BLOB_HOST}${details.bodyImage}`

    this._id = email._id

    this.headerBackground = details.headerBackground
    this.bodyBackground = details.bodyBackground
    this.buttonColor = details.buttonColor
    this.buttonBackground = details.buttonBackground
    this.buttonBorder = details.buttonBorder

    this.emailForm.get('title').setValue(title)
    this.emailForm.get('details').get('headerBackground').setValue(details.headerBackground)
    this.emailForm.get('details').get('headerImage').setValue(details.headerImage)
    this.emailForm.get('details').get('bodyImage').setValue(details.bodyImage)
    this.emailForm.get('details').get('bodyBackground').setValue(details.bodyBackground)
    this.emailForm.get('details').get('bodyText').setValue(details.bodyText)
    this.emailForm.get('details').get('buttonText').setValue(details.buttonText)
    this.emailForm.get('details').get('buttonLink').setValue(details.buttonLink)
    this.emailForm.get('details').get('buttonColor').setValue(details.buttonColor)
    this.emailForm.get('details').get('buttonBackground').setValue(details.buttonBackground)
    this.emailForm.get('details').get('buttonBorder').setValue(details.buttonBorder)

    this.loading = false
  }

  colorPickerChange(formControlName, color) {
    this.emailForm.get('details').get(formControlName).setValue(color)
  }

  onHeaderImageChange(headerImage) {
    const value = this.emailForm.get('details').get('headerImage').value
    const canChange = headerImage.indexOf(environment.BLOB_HOST) === -1 && value !== headerImage
      
    if (canChange) {
      this.headerImageChange = true
      this.headerImage = ''
      this.emailForm.get('details').get('headerImage').setValue(headerImage)
    }

  }

  onBodyImageChange(bodyImage) {
    const value = this.emailForm.get('details').get('bodyImage').value
    const canChange = bodyImage.indexOf(environment.BLOB_HOST) === -1 && value !== bodyImage

    if (canChange) {
      this.bodyImageChange = true
      this.bodyImage = ''
      this.emailForm.get('details').get('bodyImage').setValue(bodyImage)
    }

  }

  ckeditorChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.emailForm.get('details').get('bodyText').setValue(data)
  }

  validate() {
    const { bodyText, buttonText, buttonLink } = this.emailForm.get('details').value

    if (!bodyText) {
      this.confirmation('Seu template não possui <b>TEXTO</b>. Deseja enviar assim mesmo?')
    } else if (!buttonText) {
      this.confirmation('Seu template não possui <b>BOTÃO</b>. Deseja enviar assim mesmo?')
    } else if (!buttonLink) {
      this.confirmation('Seu <b>BOTÃO</b> não possui <b>URL</b>. Deseja enviar assim mesmo?')
    } else {
      this.update()
    }
  }

  confirmation(message) {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '250px',
      height: 'auto',
      data: { title: 'Template de email', message },
    });

    dialogRef.afterClosed.subscribe(confirm => {
      if (confirm) this.update()
    });
  }

  async update() {
    if (this.emailForm.invalid) return false

    this.loading = true

    const { headerImage, bodyImage } = this.emailForm.get('details').value
    let headerImageBlob
    let bodyImageBlob
    let data

    try {
      if (this.headerImageChange && headerImage)
        headerImageBlob = await this.communicationTemplateService.blob(headerImage, 'temp-image.jpg', 'crm/').toPromise() 

      if (this.bodyImageChange && bodyImage)
        bodyImageBlob = await this.communicationTemplateService.blob(bodyImage, 'temp-image.jpg', 'crm/').toPromise() 
    } catch (error) {
      this.updateError(error)
    }

    data = {
      ...this.emailForm.value,
      type: 'email',
      venue: this.tokenService.getCurrentVenue()._id
    }

    if (headerImageBlob)
      data.details.headerImage = `/${headerImageBlob.blobUrl}`

    if (bodyImageBlob)
      data.details.bodyImage = `/${bodyImageBlob.blobUrl}`

    this.communicationTemplateService.updateById(this._id, data).subscribe(
      data => this.updateSuccess(),
      error => this.updateError(error)
    )
  }

  updateSuccess() {
    this.loading = false
    this.toast.show({ text: message.EMAIL_UPDATE_SUCCESS, type: 'success' })
    this.router.navigate(['/email'])
  }

  updateError(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
    return false
  }

}
