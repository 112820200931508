import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tg-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {

  @Input() label: string = ''
  @Output() onNodeClick = new EventEmitter<any>();

  @ViewChild('ul') ul: ElementRef;
  @ViewChild('content') content: ElementRef;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }

  nodeClick(event) {
    const checkbox = event.currentTarget.querySelector('input[type="checkbox"]')
    checkbox.click()
    this.onNodeClick.emit(checkbox.checked)
  }

}
