import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UnsubscribeService } from "src/app/services/unsubscribe.service";

@Component({
  selector: "app-unsubscribe",
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["./unsubscribe.component.scss"],
})
export class UnsubscribeComponent implements OnInit {
  encryptedEmail: string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.encryptedEmail = params.encryptedEmail;
    });
  }

  unsubscribe() {
    this.unsubscribeService
      .unsubscribeByEncryptedEmail(this.encryptedEmail)
      .subscribe();

    this.router.navigate(["/unsubscribe/confirmation"]);
  }
}
