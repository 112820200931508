import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { TokenService } from "./token.service";

@Injectable()
export class UnsubscribeService {
  url: string;

  constructor(public http: HttpClient) {
    this.url = `${environment.CUSTOMERS_API_HOST}`;
  }

  unsubscribeByEncryptedEmail(encryptedEmail: string) {
    const url = `${this.url}/communication/subscription/unsubscribe/email/${encryptedEmail}`;

    return this.http.put(url, {});
  }
}
