<div *ngIf="!loading" class="page" fxFlexFill fxLayout="column" fxLayoutGap="42px">

	<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
		<div fxLayout="column">
			<span class="title">Total de clientes</span>
			<span>{{count}} {{count === 1 ? 'pessoa' : 'pessoas'}}</span>
		</div>

		<div fxFlex fxHide.xs></div>

		<tg-button type="raised">Importar planilha de Excel</tg-button>
	</div>

	<tg-tree>
		<tg-tree-node *ngFor="let group of groupedCustomers" [label]="group.group">
			<tg-tree-content *ngFor="let customer of group.children">

				<div fxHide.lt-md class="customer-wrapper" fxLayout="row" fxLayoutGap="8px">
					<div class="avatar"></div>

					<div fxFlex fxLayout="column" fxLayoutGap="4px">
						<span class="title">{{customer.name}} {{customer.lastName}}</span>
						<span class="subtitle">{{customer.email}}</span>
						<span class="subtitle">{{customer.phone}}</span>
					</div>

					<div fxFlex fxLayout="column" fxLayoutGap="4px">
						<span>Agrupamentos deste cliente</span>
						<span class="groups">Reservas canceladas pelo client, Reservas pelo Facebook, Reservas pelo Instagram,
							Reservas pelo Google</span>
					</div>

					<div fxFlex fxLayout="column" fxLayoutGap="4px">
						<span>Pontos de fidelização (6)</span>
					</div>

					<div fxLayout="column" fxLayoutAlign="center">
						<tg-button type="raised" (click)="gotoDetail(customer._id)">Detalhes</tg-button>
					</div>
				</div>

				<div fxHide.gt-sm>
					<tg-card>
						<div fxLayout="column" fxLayoutGap="16px">

							<div fxLayout="row" fxLayoutGap="8px">
								<div fxLayout="colum" fxLayoutAlign="center center">
									<div class="avatar"></div>
								</div>

								<div fxFlex fxLayout="column" fxLayoutGap="4px">
									<span class="title">{{customer.name}} {{customer.lastName}}</span>
									<span class="subtitle">{{customer.email}}</span>
									<span class="subtitle">{{customer.phone}}</span>
								</div>
							</div>

							<tg-divider></tg-divider>

							<div fxFlex fxLayout="column" fxLayoutGap="4px">
								<span>Agrupamentos deste cliente</span>
								<span class="groups">Reservas canceladas pelo client, Reservas pelo Facebook, Reservas pelo Instagram,
									Reservas pelo Google</span>
							</div>

							<div fxFlex fxLayout="column" fxLayoutGap="4px">
								<span>Pontos de fidelização (6)</span>
							</div>

							<div fxLayout="row" fxLayoutAlign="end center">
								<tg-button type="raised" (click)="gotoDetail(customer._id)">Detalhes</tg-button>
							</div>

						</div>
					</tg-card>
				</div>

			</tg-tree-content>
		</tg-tree-node>
	</tg-tree>

</div>

<div *ngIf="loading" class="page" fxFlexFill fxLayout="column" fxLayoutAlign="center center">
	<div
		*ngIf="loading"
		style="width: 150px; height: 150px"
		class="lottie"
		data-animation-path="../../../assets/animations/food.json"
		data-anim-loop="true"
		data-name="food"
		data-speed="4"></div>
</div>