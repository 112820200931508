import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import * as ClassicEditor from '../../../components/@ckeditor/ckeditor';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-sms-update',
  templateUrl: './sms-update.component.html',
  styleUrls: ['./sms-update.component.scss']
})
export class SmsUpdateComponent implements OnInit {

  public Editor = ClassicEditor;
  public EditorConfig = { toolbar: ['bold', 'italic'] }
  public venueName = ''
  public message = ''

  loading: boolean = false
  sms: any
  smsForm: FormGroup;

  constructor(
    public app: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private communicationTemplateService: CommunicationTemplateService,
    private toast: TgToast,
    private tokenService: TokenService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.getSms(params['_id'])
    })

    this.smsForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      text: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
    const currentVenue = <any>this.tokenService.getCurrentVenue()
    this.venueName = currentVenue ? currentVenue.name.pt : ''
  }

  getSms(_id) {
    this.loading = true

    this.communicationTemplateService.findById(_id)
      .subscribe(
        data => this.setSms(data),
        error => this.error(error)
      )
  }

  setSms(sms) {
    this.sms = sms

    this.smsForm.get('title').setValue(this.sms.title)
    this.smsForm.get('text').setValue(this.sms.text)

    this.loading = false
  }

  update() {
    if (this.smsForm.invalid) return false

    this.loading = true

    let data = { ...this.smsForm.value, type: 'sms' }

    this.communicationTemplateService.updateById(this.sms._id, data).subscribe(
      data => this.updateSuccess(),
      error => this.error(error)
    )
  }

  updateSuccess() {
    this.loading = false
    this.toast.show({ text: message.SMS_UPDATE_SUCCESS, type: 'success' })
    this.router.navigate(['/sms'])
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
