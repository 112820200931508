<div class="wrapper" fxFlexFill fxLayout="column" fxLayoutAlign="center center">

  <tg-card padding="16px" class="margin-bottom-24">

    <div fxLayout="column" fxLayoutGap="8px" class="margin-top-16 margin-bottom-16">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="title">Login</span>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="subtitle">informe seus dados para entrar</span>
      </div>
    </div>

    <form [formGroup]="loginForm" fxLayout="column">
      <div fxLayout="column" fxLayoutGap="8px">
        <tg-textfield
          formControlName="email"
          name="email"
          type="email"
          placeholder="E-mail"
          label="E-mail"></tg-textfield>

        <tg-textfield
          formControlName="password"
          name="password"
          [type]="passwordFieldType"
          placeholder="Senha"
          label="Senha"
          [rightIcon]="visibilityIcon"
          [rightIconPointer]="true"
          (rightIconClick)="toggleVisibility()"
          ></tg-textfield>
      </div>

      <div fxLayout="column" fxLayoutGap="24px">
        <tg-button type="text" color="primary">Esqueceu sua senha?</tg-button>
        
        <tg-button
          type="raised"
          color="primary"
          width="100%"
          [disabled]="loginForm.invalid || loading"
          (click)="login()">Login</tg-button>
      </div>
    </form>

    <tg-divider margin="16px"></tg-divider>

    <div fxLayout="row" fxLayoutAlign="start center">
      <span class="subtitle">Não possui uma conta?</span>
      <tg-button type="text" color="primary">Criar conta</tg-button>
    </div>
    
  </tg-card>

  <img src="../../../assets/tagme-logo.svg" />

</div>