import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CrmCategoryService } from 'src/app/services/crmcategory.service';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { TgDialog } from 'src/app/components/dialog/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DialogDashboardGroupsComponent } from '../../components/dialog-dashboard-groups/dialog-dashboard-groups.component';
import { EmailSendReviewComponent } from '../email-send-review/email-send-review.component';
import { EmailSendTemplateComponent } from '../email-send-template/email-send-template.component';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {

  public loading: boolean = false

  public count: { total: 0, phone: 0, email: 0 }

  public categoryIds: Array<any> = []
  public templateId: string
  public startDate: number
  public endDate: number

  public crmCategories: Array<any> = []
  public selected = []
  public emailForm: FormGroup;
  public email: any

  public dateRange: any = { startDate: moment(), endDate: moment() }

  constructor(
    public app: AppComponent,
    private formBuilder: FormBuilder,
    private crmCategoryService: CrmCategoryService,
    private communicationTemplateService: CommunicationTemplateService,
    public dialog: TgDialog,
    private toast: TgToast,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.emailForm = this.formBuilder.group({
      type: ['group', [Validators.required]],
      aggregations: [[], [Validators.required]],
      dateRange: [{ startDate: moment(), endDate: moment() }, [Validators.required]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      let { categoryIds, templateId, startDate, endDate } = params

      if (categoryIds && !Array.isArray(categoryIds)) this.categoryIds = [categoryIds]
      else this.categoryIds = categoryIds || []

      this.templateId = templateId
      this.startDate = Number(startDate)
      this.endDate = Number(endDate)
    })
  }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.loading = true

    try {
      this.crmCategories = await <any>this.crmCategoryService.find({}, {}, { sort: 'index' }).toPromise()
      this.loadCategories()

      if (this.templateId) {
        this.email = await this.communicationTemplateService.findById(this.templateId).toPromise()
      }

      if (this.startDate && this.endDate) {
        this.emailForm.get('dateRange').setValue({
          startDate: moment(this.startDate),
          endDate: moment(this.endDate)
        })
      }

      this.emailForm.get('aggregations').valueChanges.subscribe(value => this.countCustomers())
      this.emailForm.get('dateRange').valueChanges.subscribe(value => this.countCustomers())

      try {
        await this.countCustomers()
      } catch (error) {
        this.error(error)
      }
    } catch (error) {
      this.error(error)
    }
  }

  countCustomers() {
    const aggregations = this.emailForm.get('aggregations').value
    const dateRange = this.emailForm.get('dateRange').value
    const { startDate, endDate } = dateRange

    if (!aggregations || !startDate || !endDate)
      return false

    this.loading = true

    this.crmCategoryService.customersCount({
      categoryIds: aggregations.map(d => d.value),
      startDate: startDate._d,
      endDate: endDate._d
    }).subscribe(
      data => this.setCount(data),
      error => this.error(error)
    )
  }

  setCount(count) {
    this.loading = false
    this.count = count
  }

  loadCategories() {
    let aggregations = []

    this.crmCategories.forEach(d => d.selected = false)

    this.categoryIds.forEach(_id => {
      let data = this.crmCategories.find(crmCategory => crmCategory._id === _id)

      if (data) {
        data.selected = true

        aggregations.push({
          display: data.name.pt,
          value: data._id
        })
      }
    })

    this.emailForm.get('aggregations').setValue(aggregations)
  }

  openAggregationsDialog() {
    const ref = this.dialog.open(DialogDashboardGroupsComponent, {
      width: 'auto',
      height: 'auto',
      data: { categories: JSON.parse(JSON.stringify(this.crmCategories)) }
    });

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}

      if (action === 'OK' && data) {
        this.categoryIds = data
          .filter(d => d.selected)
          .map(d => d._id)

        this.loadCategories()
      }
    })
  }

  onAggregationRemove() {
    this.categoryIds = this.emailForm.get('aggregations').value.map(d => d.value)
    this.loadCategories()
  }

  openTemplatesDialog() {
    const ref = this.dialog.open(EmailSendTemplateComponent, {
      width: 'auto',
      height: 'auto',
      card: true,
      data: {}
    })

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}

      if (action === 'OK' && data && data.email) {
        this.email = data.email
      }
    })
  }

  openReviewDialog() {
    if (this.emailForm.invalid) return false

    const ref = this.dialog.open(EmailSendReviewComponent, {
      width: 'auto',
      height: 'auto',
      maxHeight: '85%',
      card: true,
      data: {
        template: this.email._id,
        emailForm: this.emailForm.value,
        email: this.email,
        color: this.app.color,
        count: this.count,
        environment
      }
    })

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}

      if (action === 'SUCCESS')
        this.toast.show({ text: message.EMAIL_SEND_SUCCESS, type: 'success' })
    })
  }

  back() {
    this.location.back()
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
