import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CardModule } from '../components/card/card.module'
import { TextfieldModule } from '../components/textfield/textfield.module'
import { ButtonModule } from '../components/button/button.module'
import { DividerModule } from '../components/divider/divider.module'

import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SplashscreenComponent } from './pages/splashscreen/splashscreen.component';

@NgModule({
  declarations: [SigninComponent, SignupComponent, SplashscreenComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    FlexLayoutModule,
    CardModule,
    TextfieldModule,
    ButtonModule,
    DividerModule
  ]
})
export class AuthModule { }
