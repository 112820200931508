import { Component, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-dialog-sms-view',
  templateUrl: './dialog-sms-view.component.html',
  styleUrls: ['./dialog-sms-view.component.scss', './dialog-sms-view.responsive.scss']
})
export class DialogSmsViewComponent implements OnInit {

  public sms: any
  public color: any
  public venueName = ''

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    private tokenService: TokenService
  ) {
    const { data } = this.config

    this.sms = data.sms
    this.color = data.color
  }

  ngOnInit(): void {
    const currentVenue = <any>this.tokenService.getCurrentVenue()
    this.venueName = currentVenue ? currentVenue.name.pt : ''
  }

  cancel(): void {
    this.dialog.close({ action: 'CANCEL' })
  }

  remove(): void {
    this.dialog.close({ action: 'REMOVE', data: this.config.data.categories })
  }

}
