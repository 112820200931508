import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { TgDialog } from 'src/app/components/dialog/dialog';
import { DialogEmailCropperComponent } from '../dialog-email-cropper/dialog-email-cropper.component';

@Component({
  selector: 'tg-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit, OnChanges {

  @ViewChild('headerImageElement') headerImageElement: ElementRef;
  @ViewChild('headerImageInput') headerImageInput: ElementRef;

  @ViewChild('bodyImageElement') bodyImageElement: ElementRef;
  @ViewChild('bodyImageInput') bodyImageInput: ElementRef;

  @Input() headerBackground: string;
  @Input() bodyBackground: string;
  @Input() bodyText: string;
  @Input() buttonText: string;
  @Input() buttonLink: string;
  @Input() buttonColor: string;
  @Input() buttonBackground: string;
  @Input() buttonBorder: string;

  @Input() loadHeaderImage: string;
  @Input() loadBodyImage: string;

  @Input() canUpdateImages: boolean = true;

  @Output() headerImageChange = new EventEmitter<any>();
  @Output() bodyImageChange = new EventEmitter<any>();

  public model = { headerImage: null }

  public headerImage: string
  public headerImageEvent: string

  public bodyImage: string
  public bodyImageEvent: string

  public colorDefault = '#666666'
  public colorAffirmative = '388E3C'
  public colorPrimary = '#FF7E40'
  public borderDefault = '1px dashed rgb(102, 102, 102, .5)'
  public borderAffirmative = '2px dashed #388E3C'

  public headerImageRatio = 140 / 64
  public bodyImageRatio = 349 / 253

  constructor(
    private dialog: TgDialog
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadHeaderImage && changes.loadHeaderImage.currentValue)
      this.setHeaderImage(changes.loadHeaderImage.currentValue)

    if (changes.loadBodyImage && changes.loadBodyImage.currentValue)
      this.setBodyImage(changes.loadBodyImage.currentValue)
  }

  openCropperDialog(ref, event, file, ratio) {
    const dialogRef = this.dialog.open(DialogEmailCropperComponent, {
      width: 'auto',
      height: 'auto',
      data: { ref, event, file, ratio },
    });

    dialogRef.afterClosed.subscribe((confirm = {}) => {
      const { action = null, data = {} } = confirm
      const { ref, image } = data

      if (action === 'OK' && ref && image) {
        if (ref === 'headerImage') this.setHeaderImage(image)
        else if (ref === 'bodyImage') this.setBodyImage(image)
      }
    })
  }

  /* HEADER
  **************************************************/
  headerImageInputTrigger() {
    if (!this.canUpdateImages) return false

    this.headerImageInput.nativeElement.click()
  }

  onHeaderImageDragover({ element }) {
    if (!this.canUpdateImages) return false

    element.style.color = this.colorAffirmative

    element.style.borderLeft = this.borderAffirmative
    element.style.borderRight = this.borderAffirmative
    element.style.borderTop = this.borderAffirmative
  }

  onHeaderImageDragleave({ element }) {
    if (!this.canUpdateImages) return false

    element.style.color = this.colorDefault

    element.style.borderLeft = this.borderDefault
    element.style.borderRight = this.borderDefault
    element.style.borderTop = this.borderDefault
  }

  async onHeaderImageDrop({ element, files }) {
    if (!this.canUpdateImages) return false

    const ref = 'headerImage'
    const file = files[0]

    element.style.color = this.colorDefault
    element.style.borderTop = this.borderDefault
    element.style.borderLeft = 'none'
    element.style.borderRight = 'none'

    this.openCropperDialog(ref, null, file, this.headerImageRatio)
  }

  setHeaderImage(image) {
    const element = this.headerImageElement.nativeElement
    const wraper = element.parentElement

    this.headerImage = image
    element.style.background = `url(${image})`
    wraper.style.borderLeft = 'none'
    wraper.style.borderRight = 'none'

    this.headerImageChange.emit(image)
  }

  headerImageClear() {
    if (!this.canUpdateImages) return false
    
    const element = this.headerImageElement.nativeElement
    const wrapper = element.parentElement

    this.headerImage = ''
    element.style.background = 'none'
    wrapper.style.borderLeft = this.borderDefault
    wrapper.style.borderRight = this.borderDefault

    this.headerImageChange.emit('')
  }

  /* BODY
  **************************************************/
  bodyImageInputTrigger() {
    if (!this.canUpdateImages) return false
    this.bodyImageInput.nativeElement.click()
  }

  onBodyImageDragover({ element }) {
    if (!this.canUpdateImages) return false

    element.style.color = this.colorAffirmative

    element.style.borderTop = this.borderAffirmative
    element.style.borderRight = this.borderAffirmative
    element.style.borderBottom = this.borderAffirmative
    element.style.borderLeft = this.borderAffirmative
  }

  onBodyImageDragleave({ element }) {
    if (!this.canUpdateImages) return false

    element.style.color = this.colorDefault

    element.style.borderTop = this.borderDefault
    element.style.borderRight = this.borderDefault
    element.style.borderBottom = this.borderDefault
    element.style.borderLeft = this.borderDefault
  }

  async onBodyImageDrop({ element, files }) {
    if (!this.canUpdateImages) return false

    const ref = 'bodyImage'
    const file = files[0]

    element.style.color = this.colorDefault
    element.style.borderTop = this.borderDefault
    element.style.borderRight = this.borderDefault
    element.style.borderBottom = this.borderDefault
    element.style.borderLeft = this.borderDefault

    this.openCropperDialog(ref, null, file, this.bodyImageRatio)
  }

  setBodyImage(image) {
    const element = this.bodyImageElement.nativeElement
    
    this.bodyImage = image
    element.style.background = `url(${image})`

    this.bodyImageChange.emit(image)
  }

  bodyImageClear() {
    if (!this.canUpdateImages) return false
    
    const element = this.bodyImageElement.nativeElement

    this.bodyImage = ''
    element.style.background = 'none'
    
    this.bodyImageChange.emit('')
  }

}
