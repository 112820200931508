<div class="wrapper">

  <span class="tg-textfield-label" *ngIf="label" [ngStyle]="{'font-size': labelSize}">{{ label }}</span>

  <div class="input-container">
    <img
      *ngIf="leftIcon"
      class="leftIcon"
      [src]="leftIcon" />

    <input
      *ngIf="formControlName"
      #input
      [name]="name"
      [formControl]="control"
      [type]="type"
      [placeholder]="placeholder"
      [ngClass]="{'paddingLeft': leftIcon, 'paddingRight': rightIcon}"
      [mask]="mask" />

    <!-- XGH
    ================================================== -->
    <input
      *ngIf="!formControlName && !mask"
      #input
      (input)="updateValue($event.target.value)"
      [name]="name"
      [type]="type"
      [placeholder]="placeholder"
      [value]="value"
      [ngClass]="{'paddingLeft': leftIcon, 'paddingRight': rightIcon}" />

    <input
      *ngIf="!formControlName && mask"
      #input
      (input)="updateValue($event.target.value)"
      [name]="name"
      [type]="type"
      [placeholder]="placeholder"
      [value]="value"
      [ngClass]="{'paddingLeft': leftIcon, 'paddingRight': rightIcon}"
      [mask]="mask" />

    <img
      *ngIf="rightIcon"
      class="rightIcon"
      [src]="rightIcon"
      (click)="emitRightIconClick($event)"
      [ngClass]="{'pointer': rightIconPointer}" />
  </div>

</div>