<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="24px">

  <div fxLayout="row">
    <tg-button routerLink="/email">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <tg-icon src="../../../assets/icons/back.svg" [color]="app.color.PRIMARY"></tg-icon>
        <span>voltar</span>
      </div>
    </tg-button>
  </div>

  <div fxLayout="row" fxLayoutGap="32px" fxLayout.lt-md="column">

    <tg-card padding="16px">
      <form [formGroup]="emailForm" class="margin-bottom-24" fxLayout="column" fxLayoutGap="24px">

        <tg-textfield formControlName="title" label="Nome do email" placeholder="Digite aqui"></tg-textfield>

        <div fxLayout="column" fxLayoutGap="4px">
          <span class="label">Cor do topo</span>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="relative color-picker-wrapper">
              <div class="color-picker" [(colorPicker)]="headerBackground" (colorPickerChange)="colorPickerChange('headerBackground', $event)" [style.background]="headerBackground"></div>
            </div>

            <span>Cor: {{headerBackground}}</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="4px">
          <span class="label">Cor do background</span>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="relative color-picker-wrapper">
              <div class="color-picker" [(colorPicker)]="bodyBackground" (colorPickerChange)="colorPickerChange('bodyBackground', $event)" [style.background]="bodyBackground"></div>
            </div>

            <span>Cor: {{bodyBackground}}</span>
          </div>
        </div>

        <ckeditor [editor]="Editor" [config]="EditorConfig" (change)="ckeditorChange($event)"></ckeditor>

        <tg-divider></tg-divider>

        <div formGroupName="details" fxLayout="column" fxLayoutGap="16px">
          <span class="label">Botão</span>

          <tg-textfield placeholder="Texto do botão" formControlName="buttonText"></tg-textfield>

          <tg-textfield placeholder="URL" formControlName="buttonLink"></tg-textfield>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="relative color-picker-wrapper">
              <div class="color-picker" [(colorPicker)]="buttonColor" (colorPickerChange)="colorPickerChange('buttonColor', $event)" [style.background]="buttonColor"></div>
            </div>

            <span>Texto: {{buttonColor}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="relative color-picker-wrapper">
              <div class="color-picker" [(colorPicker)]="buttonBackground" (colorPickerChange)="colorPickerChange('buttonBackground', $event)" [style.background]="buttonBackground"></div>
            </div>

            <span>Background: {{buttonBackground}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="relative color-picker-wrapper">
              <div class="color-picker" [(colorPicker)]="buttonBorder" (colorPickerChange)="colorPickerChange('buttonBorder', $event)" [style.background]="buttonBorder"></div>
            </div>

            <span>Bordar: {{buttonBorder}}</span>
          </div>
        </div>

      </form>

      <tg-button width="100%" type="raised" (click)="validate()" [disabled]="emailForm.invalid">Salvar email</tg-button>
    </tg-card>



    <!-- PREVIEW
    ================================================== -->
    <tg-card fxFlex fxLayout="column" width="100%" padding="16px">
      <div fxFlex fxLayout="row" fxLayoutAlign="center">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
          <span>Design</span>
          
          <tg-email-preview
            [headerBackground]="headerBackground"
            [bodyBackground]="bodyBackground"
            [bodyText]="emailForm.get('details').get('bodyText').value"
            [buttonText]="emailForm.get('details').get('buttonText').value"
            [buttonLink]="emailForm.get('details').get('buttonLink').value"
            [buttonColor]="buttonColor"
            [buttonBackground]="buttonBackground"
            [buttonBorder]="buttonBorder"
            (headerImageChange)="headerImageChange($event)"
            (bodyImageChange)="bodyImageChange($event)"></tg-email-preview>
        </div>

      </div>
    </tg-card>

  </div>

</div>