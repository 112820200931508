<div class="smartphone" fxLayout="column">

  <div fxLayout="column" fxLayoutGap="8px" class="padding-8">
    <div fxLayout="row">
      <h2>{{ sms.title }}</h2>
    </div>

    <div fxLayout="row">
      <span>{{venueName}}</span>
    </div>
  </div>

  <div fxFlex fxLayoutAlign="center center">
    <div class="container">
      <textarea _ngcontent-syw-c8="" disabled="disabled" id="exampleFormControlInput1" placeholder="Previsualização"
        rows="5" type="text" [value]="sms.text"></textarea>
    </div>
  </div>

  <div fxLayout="row">
    <tg-button (click)="cancel()">
      <tg-icon src="../../../assets/icons/back.svg" [color]="color.PRIMARY"></tg-icon>
    </tg-button>

    <div fxFlex></div>

    <tg-button (click)="remove()">
      <tg-icon src="../../../assets/icons/delete.svg" [color]="color.PRIMARY"></tg-icon>
    </tg-button>
  </div>

</div>