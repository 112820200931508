<div class="wrapper" fxLayout="column">

  <div class="info" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" class="relative">
      <span class="title">{{ loggedUser?.name }}</span>

      <div fxLayout="row" class="venue-wrapper" (click)="toggleSplitMenu()">
        <span class="venue" fxFlex>{{ currentVenue?.name?.pt }}</span>
      </div>

      <div *ngIf="showSplitMenu && venues.length > 1" class="split-wrapper elevate-4" fxLyout="column">
        <div *ngFor="let venue of venues" fxLayout="row" class="venue-select padding-8" (click)="onVenueSelect(venue)">
          <span>{{ venue.name.pt }}</span>
        </div>
      </div>
    </div>

    <!--
    <tg-textfield fxFlex leftIcon="../../../../assets//icons/search.svg"></tg-textfield>
    -->

    <div fxFlex></div>

    <div fxLayout="row" fxLayoutGap="24px">
      <!--
      <div class="action" fxLayout="column">
        <img src="../../../../assets//icons/visibility_on.svg" />
        <span>Tutorial</span>
      </div>
      -->

      <div class="action" fxLayout="column" (click)="app.logout()">
        <img src="../../../../assets/icons/exit.svg" style="width: 24px; height: 24px;" />
        <span>Logout</span>
      </div>
    </div>
  </div>

  <tg-divider></tg-divider>

  <div class="navigation" fxLayout="row">
    <div *ngFor="let button of app.navigation" class="button" [routerLink]="button.routerLink" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <tg-icon
        [src]="button.icon"
        size="20px"
        [color]="!button.selected ? '#666666' : app.color.PRIMARY"></tg-icon>

      <span [ngClass]="{'selected': button.selected}">{{ button.text }}</span>
    </div>
  </div>

</div>
