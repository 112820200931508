<div class="wrapper">
    <div class="card">
        <img src="../../../assets/icons/ilustra-desconectado.svg" />

        <span class="title">Não deseja receber este tipo de email?</span>

        <span class="subtitle">Seu email será retirado de nosso sistema.</span>

        <button class="button-ok" (click)="unsubscribe()">Sim, retirar email</button>

        <!-- <button class="button-cancel">Cancelar</button> -->
    </div>
</div>


