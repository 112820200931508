import { Component, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';


@Component({
  selector: 'app-dialog-email-view',
  templateUrl: './dialog-email-view.component.html',
  styleUrls: ['./dialog-email-view.component.scss']
})
export class DialogEmailViewComponent implements OnInit {
  
  public email: any
  public headerImage
  public bodyImage
  public color: any

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef
  ) {
    const { data } = this.config

    this.email = data.email
    this.color = data.color
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const { data } = this.config

      this.headerImage = `${data.environment.BLOB_HOST}${data.email.details.headerImage}`
      this.bodyImage = `${data.environment.BLOB_HOST}${data.email.details.bodyImage}`
    }, 1)
  }

  ok(): void {
    this.dialog.close({ action: 'OK' })
  }

  remove(): void {
    this.dialog.close({ action: 'REMOVE' })
  }

}
