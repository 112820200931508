import { Component, OnInit } from '@angular/core';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-sms-send-review',
  templateUrl: './sms-send-review.component.html',
  styleUrls: ['./sms-send-review.component.scss']
})
export class SmsSendReviewComponent implements OnInit {

  public loading: boolean = false

  public template: any
  public sms: any
  public color: any
  public count: { total: 0, phone: 0, email: 0 }

  public testForm: FormGroup;

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    private communicationTemplateService: CommunicationTemplateService,
    private formBuilder: FormBuilder,
    private toast: TgToast,
  ) {
    const { data } = this.config

    this.template = data.template
    this.sms = data.sms
    this.color = data.color
    this.count = data.count

    this.testForm = this.formBuilder.group({
      destination: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
  }

  sendTest() {
    const body = {
      ...this.testForm.value,
      template: this.template
    }

    if (this.testForm.invalid) return false

    this.loading = true

    this.communicationTemplateService.sendTest(body).subscribe(
      data => this.sendTestSuccess(),
      error => this.sendTestError(error)
    )
  }

  send() {
    const body = {
      aggregations: this.sms.aggregations.map(d => d.value),
      startDate: this.sms.dateRange.startDate._d,
      endDate: this.sms.dateRange.endDate._d,
      template: this.template
    }

    this.loading = true

    if (this.sms.type === 'group') {
      this.communicationTemplateService.sendGroup(body).subscribe(
        data => this.sendSuccess(),
        error => this.error()
      )
    }
  }
  
  sendTestSuccess() {
    this.loading = false
    this.toast.show({ text: message.SMS_TEST_SUCCESS, type: 'success' })
  }

  sendTestError(error) {
    this.loading = false

    switch (error.status) {
      case 403:
        this.toast.show({ text: message.ERROR_TEST_COOLDOWN, type: 'error' })
        break
      default:
        this.error()
    } 
  }

  sendSuccess() {
    this.loading = false
    this.toast.show({ text: message.SMS_SEND_SUCCESS, type: 'success' })
    this.dialog.close({ action: 'SUCCESS' })
  }

  cancel(): void {
    this.dialog.close({ action: 'CANCEL' })
  }

  error() {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
