import { Component, OnInit, Input, Output, EventEmitter, Injector, forwardRef, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from './controlvalueaccessor.connector';

@Component({
  selector: 'tg-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextfieldComponent,
    multi: true
  }]
})

export class TextfieldComponent extends ControlValueAccessorConnector implements OnInit {

  @Input() formControlName: string;
  @Input() name: string = '';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() labelSize: string = '14px';
  @Input() errorMessage: string = '';
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() leftIconPointer: boolean;
  @Input() rightIconPointer: boolean;
  @Input() mask: string = '';

  @Output() rightIconClick = new EventEmitter();
  
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  @Input() ngModel: string = ''
  @Output() ngModelChange = new EventEmitter<any>();

  constructor(injector: Injector, private el: ElementRef) {
    super(injector);
  }

  ngOnInit() {
  }

  emitRightIconClick(event) {
    this.rightIconClick.emit(event)
  }

  updateValue(value) {
    this.value = value;
    this.valueChange.emit(value);
  }

}
