import { Component, OnInit } from '@angular/core';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VoucherService } from 'src/app/services/voucher.service';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-voucher-update',
  templateUrl: './voucher-update.component.html',
  styleUrls: ['./voucher-update.component.scss']
})
export class VoucherUpdateComponent implements OnInit {

  voucher: any
  loading: boolean = false
  voucherForm: FormGroup

  // TODO: UTILIZAR SLIDER
  public percentList: Array<any> = [
    { name: '05%', value: 5 },
    { name: '10%', value: 10 },
    { name: '15%', value: 15 },
    { name: '20%', value: 20 },
    { name: '25%', value: 25 },
    { name: '30%', value: 30 },
    { name: '35%', value: 35 },
    { name: '40%', value: 40 },
    { name: '45%', value: 45 },
    { name: '50%', value: 50 },
    { name: '55%', value: 55 },
    { name: '60%', value: 60 },
    { name: '65%', value: 65 },
    { name: '70%', value: 70 },
    { name: '75%', value: 75 },
    { name: '80%', value: 80 },
    { name: '85%', value: 85 },
    { name: '90%', value: 90 },
    { name: '95%', value: 95 },
    { name: '100%', value: 100 }
  ]

  // TODO: UTILIZAR SLIDER
  public hourList: Array<any> = [
    { name: 'Infinito', value: Infinity },
    { name: '07:00', value: 7 },
    { name: '08:00', value: 8 },
    { name: '09:00', value: 9 },
    { name: '10:00', value: 10 },
    { name: '11:00', value: 11 },
    { name: '12:00', value: 12 },
    { name: '13:00', value: 13 },
    { name: '14:00', value: 14 },
    { name: '15:00', value: 15 },
    { name: '16:00', value: 16 },
    { name: '17:00', value: 17 },
    { name: '18:00', value: 18 },
    { name: '19:00', value: 19 },
    { name: '20:00', value: 20 },
    { name: '21:00', value: 21 },
    { name: '22:00', value: 22 },
    { name: '23:00', value: 23 },
    { name: '00:00', value: 0 },
    { name: '01:00', value: 1 },
    { name: '02:00', value: 2 },
    { name: '03:00', value: 3 },
    { name: '04:00', value: 4 },
    { name: '05:00', value: 5 },
    { name: '06:00', value: 6 },
  ]

  public yesterday = new Date();

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    private formBuilder: FormBuilder,
    private voucherService: VoucherService,
    private toast: TgToast,
  ) {
    this.yesterday.setDate(this.yesterday.getDate() - 1)
  }

  ngOnInit(): void {
    this.voucherForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['monetario', Validators.required],
      value: ['', Validators.required],
      minValue: [''],
      description: ['', Validators.required],
      voucher: ['', Validators.required],
      exceptHolidays: [false],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      validationHourStart: ['', Validators.required],
      validationHourEnd: ['', Validators.required],
      quantity: ['', Validators.required],
    });

    this.listeners()

    this.getVoucher()
  }

  getVoucher() {
    const { _id } = this.config.data
    
    this.voucherService.findById(_id).subscribe(
      data => this.setVoucher(data),
      error => this.error(error)
    )
  }

  setVoucher(voucher) {
    this.voucher = voucher

    this.voucherForm.get('name').setValue(this.voucher.name)
    this.voucherForm.get('type').setValue(this.voucher.type)
    this.voucherForm.get('value').setValue(this.voucher.value)
    this.voucherForm.get('minValue').setValue(this.voucher.minValue)
    this.voucherForm.get('description').setValue(this.voucher.description)
    this.voucherForm.get('voucher').setValue(this.voucher.voucher)
    this.voucherForm.get('exceptHolidays').setValue(this.voucher.exceptHolidays)
    this.voucherForm.get('startDate').setValue(this.voucher.startDate)
    this.voucherForm.get('endDate').setValue(this.voucher.endDate)
    this.voucherForm.get('validationHourStart').setValue(this.voucher.validationHourStart)
    this.voucherForm.get('validationHourEnd').setValue(this.voucher.validationHourEnd)
    this.voucherForm.get('quantity').setValue(this.voucher.quantity)
  }

  listeners() {
    this.voucherForm.get('type').valueChanges.subscribe(value => {
      this.voucherForm.get('value').setValue('')
      this.voucherForm.get('minValue').setValue('')
    })
  }

  cancel(): void {
    this.dialog.close()
  }

  ok(): void {
    if (this.voucherForm.valid)
      this.dialog.close({ action: 'UPDATE', data: this.voucherForm.value, _id: this.voucher._id })
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
