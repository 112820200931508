import { Component, OnInit, Input, Injector, Output, EventEmitter, SimpleChange, ElementRef } from '@angular/core';
import { ControlValueAccessorConnector } from './controlvalueaccessor.connector';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxComponent,
    multi: true
  }]
})

export class CheckboxComponent extends ControlValueAccessorConnector implements OnInit {

  @Input() formControlName: string;
  
  @Input() ngModel: boolean = false
  @Output() ngModelChange = new EventEmitter<any>();

  public selected: boolean

  constructor(injector: Injector, private el: ElementRef) {
    super(injector);
  }

  ngOnInit(): void {
  }

  onChange(event) {
    this.ngModel = event.currentTarget.checked
    this.ngModelChange.emit(this.ngModel)
  }

}
