import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { UserService } from "./services/user.service";
import { TokenService } from "./services/token.service";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule } from "@angular/forms";

import { AuthModule } from "./auth/auth.module";
import { CrmModule } from "./crm/crm.module";

import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AuthModule,
    CrmModule,
  ],
  providers: [
    UserService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [UserService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initApp(userService: UserService) {
  const token = new TokenService();

  return () => {
    return userService
      .me()
      .toPromise()
      .then((data) => {
        const { user, venues } = data as any;

        if (user) {
          token.setUser(user);
          token.setVenues(venues);
        }
      })
      .catch(error => {
        // token.destroyUser()
        // token.destroyVenues()
      })
  }
}

/*
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendProvider } from './core/helpers/fake-backend';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ModalComponent } from './crm/modal/modal.component';
// import { ModaldeleteComponent } from './crm/modaldelete/modaldelete.component';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';


// import { CreatesendsmsComponent } from './crm/createsendsms/createsendsms.component';
// import { ModaldeletecompleteComponent } from './crm/modaldeletecomplete/modaldeletecomplete.component';

@NgModule({
  declarations: [
    AppComponent,
    // CreatesendsmsComponent,
    // ModaldeletecompleteComponent,
    // ModalComponent,
    // ModaldeleteComponent
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    NgbModule,
    ColorPickerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
    // provider used to create fake backend
    FakeBackendProvider
  ],
  bootstrap: [AppComponent],
  // entryComponents: [
  //   ModalComponent
  // ]
  
})
export class AppModule { }
*/
