<div class="page" fxFlexFill fxLayout="column" fxLayoutGap="24px">

  <div fxLayou="row" fxLayoutGap="8px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column">
    <div fxFlex fxLayout="column" fxLayoutGap="8px">
      <span>Visualização por período</span>

      <div class="date-wrapper" fxLayou="row" fxLayoutAlign="start center" fxFlex.xs>
        <tg-icon src="../../../assets/icons/date_range.svg" [color]="app.color.PRIMARY"></tg-icon>

        <input
          fxFlex
          type="text"
          class="date"
          ngxDaterangepickerMd
          [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}"
          [(ngModel)]="dateRange"
          (datesUpdated)="onDateChange()"
          [disabled]="loading" />
      </div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="end end">
      <tg-button fxFlex.xs width="100%" type="raised" (click)="openAggregationsDialog()" [disabled]="loading">Adicionar agrupamento de clientes</tg-button>
    </div>
  </div>

  <div *ngIf="aggregations.length && !loading" fxLayout="column" fxLayoutGap=16px>
    
    <div fxFlex class="grid-item-container">
      <tg-card *ngFor="let aggregation of aggregations" class="grid-item">
        <div class="padding-16" fxLayout="column" fxLayoutGap="24px" fxFlex>

          <div fxLayout="row">
            <span>{{ aggregation?.name?.pt }}</span>
          </div>
        
          <div fxLayout="column" fxLayoutGap="16px" fxFlex>
            <div *ngFor="let data of aggregation?.data" fxLayout="row" [fxLayoutAlign]="data.type === 'primary'? 'start end' : 'start center'" fxLayoutGap="2px">
              <span [ngClass]="data.type">{{ data.value }}</span>
              <span class="subtitle">{{ data.hint }}</span>

              <tg-icon
                *ngIf="data.type === 'subtitle'"
                class="pointer"
                src="../../../assets/icons/info.svg"
                size="20px"
                [color]="app.color.PRIMARY"
                tooltip="Assentos ocupados na reserva ou cadastrados na lista de espera."
                placement="bottom"
                theme="light"></tg-icon>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
            <!--<tg-button type="outline" fxFlex width="100%">Ver clientes</tg-button>-->
            <tg-button type="outline" fxFlex width="100%" (click)="gotoSend(aggregation.categoryId, 'sms')">Enviar SMS</tg-button>
            <tg-button type="outline" fxFlex width="100%" (click)="gotoSend(aggregation.categoryId, 'email')">Enviar email</tg-button>
          </div>

        </div>
      </tg-card>
    </div>

    <!--
    GRÁFICOS COM DRILLDOWN - EM DESENVOLVIMENTO
    <tg-divider></tg-divider>

    <div *ngFor="let chart of charts" fxLayout="row">
      <div fxHide.xs fxFlex="50" fxFlex.md="80" fxFlex.sm="100" style="height: 250px;">
        <ngx-charts-advanced-pie-chart
        [results]="chart.data"
        [label]="chart.group"></ngx-charts-advanced-pie-chart>
      </div>
    </div>
    -->

  </div>

  <div *ngIf="!aggregations.length && !loading" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <tg-icon src="../../../assets/icons/group.svg" color="rgb(204, 204, 204, 0.2)" size="128px"></tg-icon>
    <span class="hint">Sem agrupamentos de clientes.</span>
  </div>

  <div [ngClass]="{'hidden': !loading}" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <div
      style="width: 150px; height: 150px"
      class="lottie"></div>
  </div>

</div>