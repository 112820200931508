import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CommunicationTemplateService } from '../../../services/communicationtemplate.service'
import { Router } from '@angular/router';
import { TgDialog } from '../../../components/dialog/dialog'
import { DialogEmailViewComponent } from '../../components/dialog-email-view/dialog-email-view.component'
import { environment } from '../../../../environments/environment';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss', './email.responsive.scss']
})
export class EmailComponent implements OnInit {

  public loading: boolean = false
  public emails: Array<any> = []
  public showFilters: boolean = false
  public filterTimeout

  public filters = [
    { type: 'text', selected: false, name: 'Título', property: 'title', value: '' }
  ]

  public selectedFilters = []

  constructor(
    public app: AppComponent,
    private communicationTemplateService: CommunicationTemplateService,
    private router: Router,
    private dialog: TgDialog,
    private toast: TgToast,
  ) {

  }

  ngOnInit(): void {
    this.getEmails()
  }

  selectedFiltersChange(filter) {
    filter.value = ''
    this.selectedFilters = this.filters.filter(d => d.selected)
    this.getEmails()
  }

  filterChange() {
    clearTimeout(this.filterTimeout)

    this.filterTimeout = setTimeout(() => {
      this.getEmails()
    }, 500)
  }

  getMatch() {
    const filters = {}

    this.filters.forEach(d => {
      if (d.selected && d.value) filters[d.property] = d.value
    })

    return filters
  }

  getEmails() {
    this.loading = true

    this.communicationTemplateService.findByType(
      'email',
      this.getMatch(),
      { title: 1, details: 1, sent: 1, open: 1 },
      { sort: '-created_at', regexp: 1 }
    ).subscribe(
      data => this.setEmails(data),
      error => this.error(error)
    )
  }

  setEmails(data) {
    this.emails = data
    this.loading = false
  }

  openEmailDialiog(email) {
    const ref = this.dialog.open(DialogEmailViewComponent, {
      width: 'auto',
      height: 'auto',
      maxHeight: '85%',
      data: { email, color: this.app.color, environment }
    })

    ref.afterClosed.subscribe(result => {
      const { action } = result || {}

      if (action === 'REMOVE')
        this.remove(email._id)
    })
  }

  gotoEdit(_id) {
    this.router.navigate([`email/update/${_id}`])
  }

  gotoSend(_id) {
    this.router.navigate(['/email/send'], {
      queryParams: { templateId: _id }
    })
  }

  remove(_id) {
    this.loading = true

    this.communicationTemplateService.updateById(_id, { deleted: true })
      .subscribe(
        data => this.removeSuccess(),
        error => this.error(error)
      )
  }

  removeSuccess() {
    this.toast.show({ text: message.EMAIL_REMOVE_SUCCESS, type: 'success' })
    this.getEmails()
  }

  error(error) {
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

  toggleFilters() {
    this.showFilters = !this.showFilters
  }

  setShowFilters(showFilters: boolean) {
    this.showFilters = showFilters
  }

}

// TODO: PERSISTIR FILTROS