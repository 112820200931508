import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/services/validation.service';
import { UserService } from 'src/app/services/user.service';
import { TokenService } from 'src/app/services/token.service';
import { TgToast } from 'src/app/components/toast/toast';
import { message } from '../../../constants'

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss', './signin.responsive.scss']
})
export class SigninComponent implements OnInit {

  loading: boolean = false
  visible: boolean = false
  iconVisible: string = '../../../assets/icons/visibility_on.svg'
  iconInvisible: string = '../../../assets/icons/visibility_off.svg'
  visibilityIcon: string = this.iconVisible
  passwordFieldType: string = 'password'
  
  loginForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private tokenService: TokenService,
    private toast: TgToast,
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, ValidationService.email]],
      password: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
  }

  toggleVisibility() {
    this.visible = !this.visible;
    this.visibilityIcon = this.visible ? this.iconInvisible : this.iconVisible;
    this.passwordFieldType = this.visible ? 'text' : 'password'
  }

  login() {
    const { email, password } = this.loginForm.value

    if (this.loginForm.valid) {
      this.loading = true

      this.userService.login(email, password).subscribe(
        data => this.loginSuccess(data),
        error => this.error(error)
      )
    }

  }

  loginSuccess(data) {
    this.loading = false
    const { token, user, venues } = data

    this.tokenService.setToken(token)
    this.tokenService.setUser(user)
    this.tokenService.setVenues(venues)

    if (venues.length)
      this.tokenService.setCurrentVenue(venues[0])

    this.router.navigate(['/dashboard', {}]);
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.SIGNIN_ERROR, type: 'error' })
  }

}
