import { Component, OnInit } from '@angular/core';
import { TgDialog } from '../../../components/dialog/dialog'
import { DialogDashboardGroupsComponent } from '../../components/dialog-dashboard-groups/dialog-dashboard-groups.component'
import { CrmCategoryService } from '../../../services/crmcategory.service'
import { AppComponent } from 'src/app/app.component';
import { TgToast } from 'src/app/components/toast/toast';
import * as Lottie from 'lottie-web'
import * as moment from 'moment'
import { Router } from '@angular/router';
import { CrmDashboardService } from 'src/app/services/crmdashboard.service';

import { Dashboard } from '../../../models'
import { message } from '../../../constants'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', './dashboard.responsive.scss']
})
export class DashboardComponent implements OnInit {

  public selected = []
  public loading: boolean = false
  public crmCategories: Array<any> = []
  public dashboard: Dashboard
  public aggregations: Array<any> = []
  public charts: Array<any> = []
  public dateRange: any = { startDate: moment(), endDate: moment() }

  public lottie: any = Lottie
  public lottieConfig: Object;

  constructor(
    public app: AppComponent,
    public dialog: TgDialog,
    private toast: TgToast,
    private crmCategoryService: CrmCategoryService,
    private crmDashboardService: CrmDashboardService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.init()
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.loadLottie() }, 1)
  }

  loadLottie() {
    this.lottie.loadAnimation({
      container: document.querySelector('.lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../../../assets/animations/food.json',
      resize: 5000
    });

    this.lottie.setSpeed(4)
  }

  async init() {
    this.loading = true

    try {
      const dashboard = await <Dashboard>this.crmDashboardService.find({}, {}).toPromise()
      const crmCategories = await <any>this.crmCategoryService.find({}, {}, { sort: 'index' }).toPromise()

      this.dashboard = dashboard[0] || { aggregations: [], startDate: null, endDate: null }
      this.crmCategories = crmCategories

      this.setCrmCategories()
    } catch (error) {
      this.error(error)
    }

  }

  async setCrmCategories() {
    const { selected, startDate, endDate } = this.dashboard

    if (!selected || !startDate || !endDate) {
      this.loading = false
      return false
    }

    this.selected = selected

    this.crmCategories.forEach(crmCategory =>
      crmCategory.selected = this.selected.find(aggregation => crmCategory._id === aggregation) !== undefined
    )

    this.dateRange = { startDate: moment(startDate), endDate: moment(endDate) }

    await this.getAggregations()
  }

  onDateChange() {
    const { startDate, endDate } = this.dateRange

    if (startDate && endDate && this.selected.length) {
      this.getAggregations()
      this.updateDashboard()
    }
  }

  getAggregations() {
    this.loading = true

    const { startDate = moment(), endDate = moment() } = this.dateRange

    this.crmCategoryService.kpis({ categoryIds: this.selected, startDate: startDate._d, endDate: endDate._d }).subscribe(
      data => this.setAggregations(data),
      error => this.error(error)
    )
  }

  setAggregations(aggregations) {
    this.aggregations = aggregations
    this.loading = false

    /*
    debugger
    
    const charts = []

    const groupByParent = this.groupBy(aggregations, 'parent')
    
    for (const group in groupByParent) {
      const chart = { group, data: [] }

      if (group === 'undefined') continue

      chart.data = groupByParent[group].map(category => {
        return {
          name: category.name.pt,
          value: category.data.find(d => d.type === 'primary').value
        }
      })

      charts.push(chart)
      this.charts = charts
    }
    */
  }

  // TODO: COLOCAR EM UM ARQUIVO "HELPER"
  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)

      return result;
    }, {})
  };

  openAggregationsDialog() {
    const ref = this.dialog.open(DialogDashboardGroupsComponent, {
      width: 'auto',
      height: 'auto',
      data: { categories: JSON.parse(JSON.stringify(this.crmCategories)) }
    });

    ref.afterClosed.subscribe(result => {
      const { action, data } = result || {}
      let selected = []

      if (action === 'OK' && data) {
        this.selected = data
          .filter(d => d.selected)
          .map(d => d._id)

        this.crmCategories = data

        this.getAggregations()
        this.updateDashboard()
      }
    })
  }

  updateDashboard() {
    let { startDate, endDate } = this.dateRange

    this.crmDashboardService.update(
      { selected: this.selected, startDate: startDate._d, endDate: endDate._d }
    ).toPromise()
  }

  gotoSend(categoryId, page) {
    let { startDate, endDate } = this.dateRange
    let params = { categoryIds: [categoryId] }
    let queryParams = {}

    if (startDate && endDate) {
      startDate = startDate._d.getTime()
      endDate = endDate._d.getTime()

      queryParams = { ...params, startDate, endDate }
    }

    this.router.navigate([`/${page}/send`], { queryParams })
  }

  error(error) {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
