<div class="wrapper" fxLayout="column" fxLayoutGap="24px">

  <div fxLayout="row">
    <span class="title">{{ config.data.title }}</span>
  </div>

  <div fxLayout="column">
    <span class="message" [innerHTML]="config.data.message"></span>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <tg-button (click)="cancel()">Cancelar</tg-button>
    <tg-button type="raised" (click)="ok()">OK</tg-button>
  </div>

</div>
