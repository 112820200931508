import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../services/app.service'
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';

@Injectable()
export class UserService extends AppService {

  url: string

  constructor(
    public http: HttpClient,
    private tokenService: TokenService
  ) {
    super('user', http);
    this.url = `${environment.API_HOST}/user`;
  }

  me() {
    return this.http.get(`${this.url}/me`)
  }

  login(email, password) {
    return this.http.post(`${this.url}/login`, { email, password })
  }

  logout() {
    this.tokenService.destroyToken()
    this.tokenService.destroyUser()
    //return this.http.get(`${this.url}/logout`)
  }

}