import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: 'td[end]'
})
export class EndDirective implements OnInit {

  @Input() data: Array<any> = []

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.textAlign = 'end'
  }

  ngOnInit() {
  }

}
