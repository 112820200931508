import { Component, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/components/dialog/dialog-ref';
import { DialogConfig } from 'src/app/components/dialog/dialog-config';
import { CommunicationTemplateService } from 'src/app/services/communicationtemplate.service';
import { message } from '../../../constants'
import { TgToast } from 'src/app/components/toast/toast';

@Component({
  selector: 'app-email-send-template',
  templateUrl: './email-send-template.component.html',
  styleUrls: ['./email-send-template.component.scss', './email-send-template.responsive.scss']
})
export class EmailSendTemplateComponent implements OnInit {

  loading: boolean = false
  public emails: Array<any> = []
  public currentEmail: any

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    private communicationTemplateService: CommunicationTemplateService,
    private toast: TgToast
  ) { }

  ngOnInit(): void {
    this.getEmails()
  }

  getEmails() {
    this.loading = true

    this.communicationTemplateService.find(
      { type: 'email', deleted: false },
      { },
      { sort: '-created_at' }
    ).subscribe(
      data => this.setEmails(data),
      error => this.error(error)
    )
  }

  setEmails(emails) {
    this.emails = emails
    this.loading = false
  }

  select(email) {
    this.emails.forEach(d => d.selected = false)
    email.selected = true

    this.currentEmail = email
  }

  cancel(): void {
    this.dialog.close({action: 'CANCEL'})
  }

  ok(): void {
    this.dialog.close({ action: 'OK', data: { email: this.currentEmail } })
  }

  error(error): void {
    this.loading = false
    this.toast.show({ text: message.ERROR_DEFAULT, type: 'error' })
  }

}
