import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../services/app.service'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import * as qs from 'qs';

@Injectable()
export class CommunicationInstanceService extends AppService {

  url: string

  constructor(
    public http: HttpClient,
  ) {
    super('communicationinstance', http);
    this.url = `${environment.API_HOST}/communicationinstance`;
  }

  findByDate(startDate, endDate, match = {}, fields = {}, options = {}) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next([]))

    if (!startDate || !endDate)
      return new Observable(observer => observer.next([]))

    const querystring = qs.stringify({ startDate, endDate, match, fields, options }, { encode: false })
    return this.http.get(`${this.url}/${venue}/findbydate?${querystring}`)
  }

  countByDate(startDate, endDate, match) {
    const venue = this.getCurrentVenue()._id

    if (!venue)
      return new Observable(observer => observer.next(0))

    if (!startDate || !endDate)
      return new Observable(observer => observer.next(0))

    const querystring = qs.stringify({ startDate, endDate, match }, { encode: false })
    return this.http.get(`${this.url}/${venue}/countbydate?${querystring}`)
  }

}